import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import getOr from 'lodash/fp/getOr'
import { Radio, Switch, Col, Row, Spin, Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import withSubscription from '../subscription/with-subscription'
import { LargeButton } from '../../ui/button'
import {
  getBotSchedule,
  updateBotSchedule
} from '../../data/bot-schedule/actions'
import { getFeedback } from '../../data/feedback/selectors'
import TimezoneSelect from '../settings/timezone-select'

import BotScheduleList from './bot-schedule-list'

const FB_LIST_BOT_SCHEDULES = 'fbListBotSchedules'
const FB_UPDATE_BOT_SCHEDULES = 'fbUpdatetBotSchedules'

const StyledSpin = styled(Spin)`
  .ant-spin-text {
    margin-top: 20px;
  }
`

const BotScheduleContainer = (props) => {
  const { onSubmit, onInit, listBotSchedulesFb, updateBotSchedulesFb } = props

  const [form, setForm] = useState({
    run_everyday: false,
    enabled: false,
    schedules: [
      {
        start_time: '00:00',
        end_time: '23:59',
      }
    ]
  })

  useEffect(() => {
    onInit().then(data => {
      setForm({
        run_everyday: data.run_everyday,
        enabled: data.enabled,
        schedules: data.schedules,
        timezone_id: data.timezone_id
      })
    })
  }, [])

  const { enabled, timezone_id, schedules } = form

  const handleChange = (key, value) => setForm({ ...form, [key]: value })

  const handleSubmit = () => onSubmit(form)

  const isLoading = getOr(false, 'isLoading')(listBotSchedulesFb)
  const isUpdateLoading = getOr(false, 'isLoading')(updateBotSchedulesFb)

  return (
      <div>
        <Row>
          <Col xs={24} md={16}>
            <p style={{ fontWeight: 'bold', marginTop: '15px' }}>Status</p>
            <Switch
              checked={enabled}
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              onChange={(checked) => handleChange('enabled', checked)}
            />
            <Col xs={12} md={12}>
              <h2 style={{ display: 'inline-block', fontWeight: 'bold', marginTop: '15px' }}>
                Time Zone
              </h2>
              <TimezoneSelect value={timezone_id} disabled={true}/>
            </Col>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col xs={24} md={16}>
            {isLoading ? (
              <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <StyledSpin
                  indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
                  tip='Loading...'
                  size='large'
                >
                  <div className='content' />
                </StyledSpin>
              </div>
            ) : <><BotScheduleList schedules={schedules} handleChange={handleChange}/></>}
          </Col>
        </Row>

        <Divider />

        <LargeButton
          style={{ marginTop: '20px', padding: '0 50px' }}
          type="primary"
          onClick={handleSubmit}
          loading={isUpdateLoading}
        >
          Save
        </LargeButton>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    listBotSchedulesFb: getFeedback(state, FB_LIST_BOT_SCHEDULES),
    updateBotSchedulesFb: getFeedback(state, FB_UPDATE_BOT_SCHEDULES)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) =>
      dispatch(
        updateBotSchedule(data, {
          feedbackToGeneral: 'Bot Schedule Updated Successfully',
          feedbackKey: FB_UPDATE_BOT_SCHEDULES
        })
      ),
    onInit: () => dispatch(
      getBotSchedule({ feedbackKey: FB_LIST_BOT_SCHEDULES })
    )
  }
}

export default withSubscription(
  connect(mapStateToProps, mapDispatchToProps)(BotScheduleContainer)
)
