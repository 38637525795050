import axiosInstance from '../config'
import { AUTH_ACTION } from './constants'
import { handleError, setFeedback, setSuccessFeedback } from '../feedback/actions'

export const login = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/auth/login', data)

    const responseData = response.data

    localStorage.setItem('token', responseData.token)
    localStorage.setItem('refresh_token', responseData.refresh_token)

    return dispatch({
      type: AUTH_ACTION.UPDATE_AUTH,
      payload: responseData
    })
  } catch (error) {
    handleError(error, dispatch)
    throw error
  }
}

export const signup = (data) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/auth/signup', data)

    const responseData = response.data

    localStorage.setItem('token', responseData.token)
    localStorage.setItem('refresh_token', responseData.refresh_token)
  } catch (error) {
    handleError(error, dispatch)
    throw error
  }
}

export const refresh = (data) => async (dispatch) => {
  const response = await axiosInstance.post('/auth/refresh', data)

  const responseData = response.data

  localStorage.setItem('token', responseData.token)
  localStorage.setItem('refresh_token', responseData.refresh_token)

  return dispatch({
    type: AUTH_ACTION.UPDATE_AUTH,
    payload: responseData
  })
}

export const submitAmzUrl =
  (data, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.put('/amz_url', data)
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        return dispatch({
          type: AUTH_ACTION.UPDATE_USER,
          payload: responseData
        })
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        throw error
      }
    }

export const logOut = () => async (dispatch, getState) => {
  dispatch({
    type: AUTH_ACTION.LOGOUT
  })
}

export const requestPasswordReset =
  (data, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const response = await axiosInstance.post('/auth/password-reset', data)
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        throw error
      }
    }

export const updateUserSettings =
  (data, options = {}) =>
    async (dispatch, getState) => {
      try {
        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.put('/user/settings', data)
        const responseData = response.data

        const promise = dispatch({
          type: AUTH_ACTION.UPDATE_USER,
          payload: responseData
        })

        if (options.feedbackToGeneral) {
          return dispatch(setSuccessFeedback(options.feedbackToGeneral))
        }

        return promise
      } catch (error) {
        handleError(error, dispatch)
        throw error
      }
    }

export const getUserSleepingTime = () => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.get('/user/sleeping_time')
  const responseData = response.data

  return dispatch({
    type: AUTH_ACTION.UPDATE_USER,
    payload: responseData
  })
}
