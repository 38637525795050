import axiosInstance from '../config'
import { handleError, setFeedback, setSuccessFeedback } from '../feedback/actions'
import { SCHEDULED_OFFER_ACTION } from './constants'

export const listScheduledOffers =
  (params = {}) =>
    async (dispatch, getState) => {
      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const url = '/offers/scheduled'

      const response = await axiosInstance.get(url)
      const responseData = response.data

      dispatch({
        type: SCHEDULED_OFFER_ACTION.LIST,
        payload: responseData
      })
    }

export const bypassSelfie =
  (data, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const url = '/offers/bypass-selfie'

        const response = await axiosInstance.put(url, data)
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        dispatch(setSuccessFeedback('Bypassed Selfie Successfully'))

        return responseData
      } catch (error) {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        handleError(error, dispatch)
        throw error
      }
    }

export const uploadSelfie =
  (data, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const url = '/offers/upload-selfie'

        const response = await axiosInstance.post(url, data)
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        return responseData
      } catch (error) {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        handleError(error, dispatch)
        throw error
      }
    }
