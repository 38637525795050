import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import { listRecentOffers } from '../../data/recent/actions'
import OfferList from '../dashboard/offer-list'
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'
import { listStations } from '../../data/station/actions'
import { Col, Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { getFeedback } from '../../data/feedback/selectors'
import { getOr } from 'lodash/fp'
import { styled } from 'styled-components'
import { COLORS } from '../../constants/styles'
import withSubscription from '../subscription/with-subscription'

export const FB_RECENT_OFFERS = 'fbRecentOffers'

const StyledSpin = styled(Spin)`
  .ant-spin-text {
    margin-top: 20px;
  }
`

const StyledText = styled.p`
  color: ${COLORS.GRAY_2};
  font-style: italic;
  margin-bottom: 30px;
  margin-top: 10px;
`

const RecentContainer = (props) => {
  const { feedback, fetchStations, onInit, stations } = props

  const [recentOffers, setRecentOffers] = useState({})

  useEffect(() => {
    onInit().then((offers) => setRecentOffers(offers))
    fetchStations()
  }, [])

  const offerList = Object.values(recentOffers).map((offer) => {
    const startTime = moment.unix(offer.start_time)
    const endTime = moment.unix(offer.end_time)

    const minutesDifference = endTime.diff(startTime, 'minutes')
    const rate = (offer.block_rate * 60) / minutesDifference
    offer.pay_rate = rate

    return offer
  })

  const isLoading = getOr(false, 'isLoading')(feedback)

  return (
    <Row>
      <Col xs={24} md={14}>
        <h2>Recent Offers</h2>
        <StyledText>Recent offers are the offers that have been accepted by other users in our system.</StyledText>
        {isLoading
          ? (
            <div style={{ textAlign: 'center' }}>
              <StyledSpin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} tip='Loading...' size='large'>
                <div className='content' />
              </StyledSpin>
            </div>
            )
          : (
            <OfferList offers={offerList} stations={stations} hideCaptchaRequired />
            )}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    stations: selectors.list(state, ENTITY_TYPE.STATIONS),
    feedback: getFeedback(state, FB_RECENT_OFFERS)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(listRecentOffers({ feedbackKey: FB_RECENT_OFFERS })),
    fetchStations: () => dispatch(listStations())
  }
}

export default withSubscription(connect(mapStateToProps, mapDispatchToProps)(RecentContainer))
