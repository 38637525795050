import { useEffect } from 'react'
import { notification } from 'antd'
import isEmpty from 'lodash/fp/isEmpty'
import { connect } from 'react-redux'

import { FEEDBACK_TYPE, GENERAL_FEEDBACK_KEY } from '../data/feedback/constants'
import { getFeedback } from '../data/feedback/selectors'

const FeedbackMessageGeneral = (props) => {
  const { feedback } = props
  const [api, contextHolder] = notification.useNotification()

  useEffect(() => {
    if (feedback && !isEmpty(feedback)) {
      if (feedback.type === FEEDBACK_TYPE.ERROR) {
        api.error({
          message: feedback.message,
          duration: 3
        })
      } else if (feedback.type === FEEDBACK_TYPE.SUCCESS) {
        api.success({
          message: feedback.message,
          duration: 3
        })
      }
    }
  }, [feedback])

  return contextHolder
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state, GENERAL_FEEDBACK_KEY)
  }
}

export default connect(mapStateToProps)(FeedbackMessageGeneral)
