import moment from 'moment'

import { OFFER_ACTION } from './constants'
import { AUTH_ACTION } from '../auth/constants'

const initialState = { }

function offerReducer (state = initialState, action) {
  if (action.type === OFFER_ACTION.LIST) {
    const offers = action.payload
    for (const key of Object.keys(offers)) {
      const offer = offers[key]

      const startTime = moment.unix(offer.start_time)
      const endTime = moment.unix(offer.end_time)

      const minutesDifference = endTime.diff(startTime, 'minutes')
      const rate = (offer.block_rate * 60) / minutesDifference
      offer.pay_rate = rate
    }

    return {
      ...state,
      ...offers
    }
  }

  if (action.type === AUTH_ACTION.LOGOUT) {
    return {}
  }

  return state
}

export default offerReducer
