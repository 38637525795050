import axiosInstance from '../config'
import { OFFER_ACTION } from './constants'

export const listOffers =
  (params = {}) =>
    async (dispatch, getState) => {
      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const queryString = Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&')

      const url = `/offers${queryString ? `?${queryString}` : ''}`

      const response = await axiosInstance.get(url)
      const responseData = response.data

      dispatch({
        type: OFFER_ACTION.LIST,
        payload: responseData
      })
    }
