import { AUTH_ACTION } from '../auth/constants'
import { STATION_ACTION } from './constants'

const initialState = {}

function stationReducer (state = initialState, action) {
  if (action.type === STATION_ACTION.LIST) {
    if (action.forceRefresh) {
      return {
        ...action.payload
      }
    }

    return {
      ...state,
      ...action.payload
    }
  }

  if (action.type === STATION_ACTION.UPDATE) {
    return {
      ...state,
      ...action.payload
    }
  }

  if (action.type === AUTH_ACTION.LOGOUT) {
    return {}
  }

  // otherwise return the existing state unchanged
  return state
}

export default stationReducer
