import React, { useEffect, useState } from 'react'
import { RedoOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import orderBy from 'lodash/fp/orderBy'
import moment from 'moment'
import { connect } from 'react-redux'

import { getAcceptedOffers } from '../../data/monitor/actions'
import { maybeRound } from '../dashboard/helpers'
import { getFeedback } from '../../data/feedback/selectors'

const FB_FETCH_ACCEPTED_OFFERS = 'fbFetchAcceptedOffers'

const AcceptedOfferTable = (props) => {
  const { feedback, onInit } = props

  const [acceptedOffers, setAcceptedOffers] = useState({})

  const handleFetch = () => onInit().then((offers) => setAcceptedOffers(offers))

  useEffect(() => {
    handleFetch()
  }, [])

  const columns = [
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email'
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: 'Created At',
      render: (createdAt) => moment(createdAt).calendar()
    },
    {
      key: 'block_rate',
      dataIndex: 'block_rate',
      title: 'Total Pay',
      render: (blockRate) => `$${maybeRound(blockRate)}`
    },
    {
      key: 'rate',
      title: 'Rate',
      render: (offer) => {
        const start = moment.unix(offer.start_time)
        const end = moment.unix(offer.end_time)
        const duration = moment.duration(end.diff(start))
        return maybeRound(parseFloat(offer.block_rate / duration.asHours())) + ' / hr'
      }
    }
  ]

  const dataSource = orderBy((offer) => moment(offer.created_at).unix())(['desc'])(Object.values(acceptedOffers)).map(
    (offer) => ({ ...offer, key: offer.id })
  )

  return (
    <div>
      <Button loading={feedback.isLoading} onClick={handleFetch} icon={<RedoOutlined />}>Refresh</Button>
      <Table columns={columns} dataSource={dataSource} loading={feedback.isLoading} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state, FB_FETCH_ACCEPTED_OFFERS)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(getAcceptedOffers({ feedbackKey: FB_FETCH_ACCEPTED_OFFERS }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptedOfferTable)
