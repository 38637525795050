import React, { useMemo, useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import styled from 'styled-components'
import { COLORS } from '../../constants/styles'
import { Button, Input } from 'antd'
import { LargeButton } from '../../ui/button'

const StyledCardElement = styled(CardElement)`
  border-radius: 8px;
  padding: 12px 10px;
  border: 1px solid rgb(217, 217, 217);
  margin-top: 10px;

  ${(props) =>
    props.focus &&
    `
  border-color: ${COLORS.ORANGE_FLEX};
  `}
`

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: '#424770',
          letterSpacing: '0.025em',
          '::placeholder': {
            color: '#aab7c4',
            fontSize: ''
          }
        },
        invalid: {
          color: '#9e2146'
        }
      }
    }),
    []
  )

  return options
}

const PaymentMethodForm = (props) => {
  const { buttonLabel, buttonProps, onCancel, onConfirm, showCancel } = props

  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions()

  const [cardholderName, setCardHolderName] = useState('')
  const [isFocus, setIsFocus] = useState(false)

  const handleChange = (e) => setCardHolderName(e.target.value)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: cardholderName
      }
    })

    return onConfirm(payload.paymentMethod.id)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input placeholder='Cardholder Name' size='large' onChange={handleChange} value={cardholderName} />

      <StyledCardElement
        focus={isFocus}
        options={options}
        onReady={() => {
          console.log('CardElement [ready]')
        }}
        onChange={(event) => {
          console.log('CardElement [change]', event)
        }}
        onBlur={() => setIsFocus(false)}
        onFocus={() => setIsFocus(true)}
      />
      <div style={{ marginTop: '15px', textAlign: 'right' }}>
        {showCancel && onCancel && (
          <Button onClick={onCancel} style={{ marginRight: '10px' }}>
            Cancel
          </Button>
        )}
        <LargeButton htmlType='submit' type='primary' disabled={!stripe} {...buttonProps}>
          {buttonLabel || 'Add'}
        </LargeButton>
      </div>
    </form>
  )
}

export default PaymentMethodForm
