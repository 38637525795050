import { FEEDBACK_ACTION, GENERAL_FEEDBACK_KEY } from './constants'
import { AUTH_ACTION } from '../auth/constants'

const initialState = { }

function feedbackReducer (state = initialState, action) {
  if (action.type === FEEDBACK_ACTION.SET_GENERAL) {
    return {
      ...state,
      [GENERAL_FEEDBACK_KEY]: action.payload
    }
  }

  if (action.type === FEEDBACK_ACTION.CLEAR_GENERAL) {
    return {
      ...state,
      [GENERAL_FEEDBACK_KEY]: null
    }
  }

  if (action.type === FEEDBACK_ACTION.SET_FEEDBACK) {
    const { feedbackKey, feedback } = action.payload
    return {
      ...state,
      [feedbackKey]: feedback
    }
  }

  if (action.type === FEEDBACK_ACTION.CLEAR_FEEDBACK) {
    const { feedbackKey } = action.payload
    const newState = { ...state }
    delete newState[feedbackKey]

    return {
      ...newState
    }
  }

  if (action.type === AUTH_ACTION.LOGOUT) {
    return {}
  }

  return state
}

export default feedbackReducer
