import { Button, Modal } from 'antd'
import React, { useState } from 'react'

import PaymentMethodForm from './payment-method-form'
import { LargeButton } from '../../ui/button'

const PaymentMethodDialog = (props) => {
  const { onConfirm } = props

  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const handleConfirm = (data) => {
    handleClose()
    return onConfirm(data)
  }

  return (
    <>
      <LargeButton type='primary' onClick={handleOpen}>
        Add Payment Method
      </LargeButton>
      <Modal title='Add new Payment Method' footer={[]} open={isOpen} onCancel={handleClose} destroyOnClose>
        <PaymentMethodForm onCancel={handleClose} onConfirm={handleConfirm} showCancel />
      </Modal>
    </>
  )
}

export default PaymentMethodDialog
