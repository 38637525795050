import React, { useState } from 'react'
import { LeftOutlined, MailOutlined, MailTwoTone } from '@ant-design/icons'
import { Divider, Form, Typography } from 'antd'
import { connect } from 'react-redux'
import { styled } from 'styled-components'

import { requestPasswordReset } from '../../data/auth/actions'
import withAuthMonitor from './with-auth-monitor'
import { URLS } from '../../constants'
import { NavLink } from 'react-router-dom'
import { getFeedback } from '../../data/feedback/selectors'
import { COLORS } from '../../constants/styles'
import { StyledHeader } from './auth-container'
import { StyledInput } from './auth-login'
import { LargeButton, LinkButton } from '../../ui/button'
import TallBox from '../../ui/tall-box'

const FB_PASSWORD_RESET = 'fbPasswordReset'

const IconWrap = styled.div`
  width: 80px;
  height: 80px;
  border: 2px solid ${COLORS.GREEN};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;

  svg {
    font-size: 50px;
  }
`

const AuthForgetPassword = (props) => {
  const { feedback, onSubmit } = props

  const [form] = Form.useForm()

  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmit = (form) => onSubmit(form).then(() => setIsSuccess(true))


  return (
    <TallBox>
      <div>
        <LinkButton to={URLS.AUTH_START} style={{ width: '50px' }}>
          <LeftOutlined />
        </LinkButton>
        <div style={{ paddingTop: '50px' }}>
          {isSuccess ? (
            <>
              <IconWrap>
                <MailTwoTone twoToneColor={COLORS.GREEN} />
              </IconWrap>
              <Typography.Title
                level={2}
                style={{ fontWeight: 'lighter', textAlign: 'center' }}
              >
                Check Your Email
              </Typography.Title>
              <p style={{ textAlign: 'center' }}>
                <Typography.Text>
                  Please check your email address for instructions to reset your
                  password.
                </Typography.Text>
              </p>
              <Divider />
              <NavLink to={URLS.AUTH_LOGIN}>Login</NavLink>
            </>
          ) : (
            <>
              <StyledHeader>Forgot Password?</StyledHeader>
              <p
                style={{
                  textAlign: 'center',
                  margin: '10px 0 30px',
                  color: COLORS.GRAY_2
                }}
              >
                Enter your email address and we will send you instructions to
                reset your password.
              </p>

              <Form form={form} onFinish={handleSubmit}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Please input your email!'
                    }
                  ]}
                >
                  <StyledInput placeholder="Email" prefix={<MailOutlined />} />
                </Form.Item>

                <LargeButton
                  block
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: '20px' }}
                >
                  Submit
                </LargeButton>
              </Form>

              {/* <p style={{ marginTop: '20px' }}>Email address</p>
              <Input name='email' placeholder='example@email.com' size='large' onChange={handleChange} value={email} />

              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Button
                  disabled={!canSubmit}
                  loading={feedback.isLoading}
                  onClick={handleSubmit}
                  type='primary'
                  size='large'
                >
                  Continue
                </Button>
              </div> */}

              <LinkButton
                to={URLS.AUTH_SIGNUP}
                style={{ marginTop: '15px' }}
                block
              >
                Create An Account
              </LinkButton>
            </>
          )}
        </div>
      </div>
    </TallBox>
  )
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state, FB_PASSWORD_RESET)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) =>
      dispatch(requestPasswordReset(data, { feedbackKey: FB_PASSWORD_RESET }))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuthMonitor(AuthForgetPassword))
