import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Popconfirm, Row, Switch, TimePicker } from 'antd'
import getOr from 'lodash/fp/getOr'
import orderBy from 'lodash/fp/orderBy'
import moment from 'moment'
import { connect } from 'react-redux'

import DayRangeForm from "./day-range-form";
import { addRange, listRanges, removeRange } from "../../data/day/actions";
import { isDayEnabled, rangesByDay } from "../../data/day/selectors";
import { DeleteOutlined } from "@ant-design/icons";
import { styled } from "styled-components";
import { COLORS } from "../../constants/styles";
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'

const RangeWrap = styled.div`
  margin-bottom: 15px;

  .ant-picker-disabled {
    background-color: ${COLORS.WHITE};
    margin-right: 10px;
    border-color: ${COLORS.ORANGE_FLEX};

    input {
      color: ${COLORS.ORANGE_FLEX} !important;
    }
  }
`

const StyledText = styled.p`
  color: ${COLORS.GRAY_2};
  font-style: italic;
`

const DayRange = (props) => {
  const { day, enabled, onInit, onAddRange, onDeleteRange, ranges, stations, updateDay } = props;
  
  const [isFormOpen, setIsFormOpen] = useState(false);
   
  useEffect(() => {
    onInit()
  }, [])

  const openForm = () => setIsFormOpen(true)
  const closeForm = () => setIsFormOpen(false)

  const sortedRanges = orderBy('start_time')('asc')(ranges)

  const rangeItems = sortedRanges.map((range) => {
    const startTime = moment().set({
      hour: moment(range.start_time, 'h:mm A').hour(),
      minute: moment(range.start_time, 'h:mm A').minute()
    })

    const endTime = moment().set({
      hour: moment(range.end_time, 'h:mm A').hour(),
      minute: moment(range.end_time, 'h:mm A').minute()
    })

    const minRate = getOr(null, 'min_rate')(range)
    const rangeStations = getOr([], "stations")(range)

    const stationListItems = rangeStations.map(stationId => {
      const station = stations.find(sta => sta.station_id === stationId)
      if (station) {
        return <StyledText>{station.station_name}</StyledText>
      }
      return null
    })

    return (
      <RangeWrap key={range.pk}>
        <TimePicker.RangePicker defaultValue={[startTime, endTime]} format='hh:mm a' disabled />
        <Popconfirm
          title='Delete range'
          description='Are you sure to delete this range?'
          onConfirm={() => onDeleteRange(range.pk)}
          okText='Confirm'
          cancelText='No'
        >
          <Button danger>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
        {minRate && (
          <StyledText>The Min Rate for this range is ${minRate}/hr</StyledText>
        )}
        {stationListItems}
      </RangeWrap>
    )
  })

  return (
    <Row>
      <Col xs={24} md={20} lg={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={openForm} type='primary'>
            Add new range
          </Button>

          <Switch checked={enabled} onChange={(checked) => updateDay(day, { enabled: checked })} />
        </div>

        <Divider />
        {rangeItems}
        
        <DayRangeForm day={day} open={isFormOpen} onClose={closeForm} onSubmit={onAddRange} />
      </Col>
    </Row>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { day } = ownProps

  return {
    ranges: rangesByDay(state, day),
    stations: selectors.list(state, ENTITY_TYPE.STATIONS)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { day } = ownProps

  return {
    onInit: () => dispatch(listRanges(day)),
    onAddRange: (data) => dispatch(addRange(day, data)),
    onDeleteRange: (id) => dispatch(removeRange(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DayRange)
