import React, { useEffect, useState } from 'react'
import { RedoOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Table, Tag } from 'antd'
import moment from 'moment'
import { connect } from 'react-redux'

import { getRunningUsers, restartAllSearches, restartSearch } from '../../data/monitor/actions'
import { getFeedback } from '../../data/feedback/selectors'
import { TASK_STATUS_COLOR } from './constants'

const FB_RUNNING_USERS = 'fbRunningUsers'
const FB_RESTART_ALL_SEARCHES = 'fbRestartAllSearches'

const RunningUserTable = (props) => {
  const { feedback, feedbackRestartAll, onInit, restart, restartAll } = props

  const [runningUsers, setRunningUsers] = useState({})

  const handleFetch = () => onInit().then((users) => setRunningUsers(users))

  const handleRestart = (email) => restart(email).then((user) => setRunningUsers({ ...runningUsers, [user.id]: user }))

  const handleRestartAll = () => restartAll().then((users) => setRunningUsers(users))

  useEffect(() => {
    handleFetch()
  }, [])

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Search Start',
      dataIndex: 'current_running_session',
      key: 'current_running_session',
      render: (currentSession) => moment.unix(currentSession).calendar()
    },
    {
      title: 'Last Offer',
      dataIndex: 'last_offer',
      key: 'last_offer',
      render: (lastOffer) => (lastOffer ? moment(lastOffer.created_at).fromNow() : 'None')
    },
    {
      title: 'Plan',
      dataIndex: 'subscription',
      key: 'plan',
      render: (subscription) => <div>{subscription.name || 'Free'}</div>
    },
    {
      title: 'Task Status',
      dataIndex: 'task_status',
      key: 'task_status',
      render: (status) => (
        <Tag color={TASK_STATUS_COLOR[status]} style={{ textTransform: 'capitalize' }}>
          {status}
        </Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <Popconfirm
          title='Restart this search?'
          onConfirm={() => handleRestart(record.email)}
          okText='Yes'
          cancelText='No'
        >
          <Button icon={<RedoOutlined />} />
        </Popconfirm>
      )
    }
  ]

  const dataSource = Object.values(runningUsers).map((user) => ({ ...user, key: user.id }))

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Subscription Expiry',
        dataIndex: 'subscription',
        key: 'subscription_expire',
        render: (subscription) => moment(subscription.end_date).format('YYYY-MM-DD')
      },
      {
        title: 'Plan',
        dataIndex: 'subscription',
        key: 'plan',
        render: (subscription) => <div>{subscription.name || 'Free'}</div>
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        render: (region) => <div style={{ textTransform: 'uppercase' }}>{region}</div>
      },
      {
        title: 'Timezone',
        dataIndex: 'timezone_id',
        key: 'timezone_id'
      }
    ]

    const dataSource = [record]
    return <Table columns={columns} dataSource={dataSource} pagination={false} />
  }

  return (
    <div>
      <Button
        loading={feedback.isLoading}
        onClick={handleFetch}
        icon={<RedoOutlined />}
        style={{ marginRight: '15px' }}
      >
        Refresh
      </Button>
      <Popconfirm title='Restart all searches?' onConfirm={handleRestartAll} okText='Yes' cancelText='No'>
        <Button loading={feedbackRestartAll.isLoading} icon={<RedoOutlined />}>
          Restart All
        </Button>
      </Popconfirm>
      <Tag color='green' style={{ float: 'right', margin: '1.5rem 0', fontSize: '1rem' }}>
        {dataSource.length} Running Users
      </Tag>
      <Table
        loading={feedback.isLoading}
        columns={columns}
        dataSource={dataSource}
        expandable={{
          expandedRowRender
        }}
        pagination={{
          pageSize: 100
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state, FB_RUNNING_USERS),
    feedbackRestartAll: getFeedback(state, FB_RESTART_ALL_SEARCHES)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(getRunningUsers({ feedbackKey: FB_RUNNING_USERS })),
    restart: (email) => dispatch(restartSearch(email, { feedbackToGeneral: 'Search Restarted' })),
    restartAll: () => dispatch(restartAllSearches({ feedbackKey: FB_RESTART_ALL_SEARCHES }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RunningUserTable)
