import React from 'react'
import { InputNumber, Radio } from 'antd'

import { StyledLabel } from './setting-search'
import { SEARCH_PATTERN } from '../../constants'
import { COLORS } from '../../constants/styles'

const SearchDetails = (props) => {
  const { form, handleChange } = props

  const { search_pattern, sleeping_time, search_time, total_search_time, max_search_minutes } = form

  const sleepTime = parseFloat(total_search_time - search_time).toFixed(2)

  return (
    <div>
      <StyledLabel>Search Pattern</StyledLabel>
      <Radio.Group onChange={(e) => handleChange('search_pattern', e.target.value)} value={search_pattern}>
        <Radio value={SEARCH_PATTERN.STANDARD} style={{ display: 'block' }}>
          Standard
        </Radio>
        <Radio value={SEARCH_PATTERN.ADVANCED} style={{ display: 'block' }}>
          Advanced
        </Radio>
        <Radio value={SEARCH_PATTERN.FIXED_INTERVAL} style={{ display: 'block' }}>
          Fixed Interval
        </Radio>
      </Radio.Group>

      {search_pattern === SEARCH_PATTERN.ADVANCED && (
        <div>
          <div style={{ display: 'inline-block' }}>
            <StyledLabel>Search</StyledLabel>
            <InputNumber suffix='mins' value={search_time} onChange={(value) => handleChange('search_time', value)} />
          </div>
          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            <StyledLabel>Every</StyledLabel>
            <InputNumber
              suffix='mins'
              value={total_search_time}
              onChange={(value) => handleChange('total_search_time', value)}
            />
          </div>
          <p style={{ color: COLORS.GRAY_2, marginTop: '5px', fontStyle: 'italic' }}>
            Searches for {search_time} minute(s), sleeps for {sleepTime} minute(s) for every {total_search_time}{' '}
            minute(s).
          </p>
        </div>
      )}

      {search_pattern === SEARCH_PATTERN.STANDARD && (
        <>
          <StyledLabel>Sleeping time (Mins)</StyledLabel>
          <InputNumber
            value={sleeping_time}
            onChange={(value) => handleChange('sleeping_time', value)}
            style={{ width: '100%' }}
          />
        </>
      )}

      {search_pattern === SEARCH_PATTERN.FIXED_INTERVAL && (
        <div>
          <div style={{ display: 'inline-block' }}>
            <StyledLabel>Max Search Minutes</StyledLabel>
            <InputNumber
              max={3}
              min={1}
              suffix='mins'
              value={max_search_minutes}
              onChange={(value) => handleChange('max_search_minutes', value)}
            />
          </div>
          <p style={{ color: COLORS.GRAY_2, marginTop: '5px', fontStyle: 'italic' }}>
            Automated {max_search_minutes}-minute tasks every 5 minutes! Our Interval Search Scheduler syncs with the clock.
          </p>
          <p style={{ color: COLORS.GRAY_2, marginTop: '5px', fontStyle: 'italic' }}>
            For example, the search will run at 12:00, 12:05, 12:10, 12:15,...
          </p>
        </div>
      )}
    </div>
  )
}

export default SearchDetails
