import { getOr } from 'lodash/fp'
import { AUTH_ACTION } from './constants'

const initialState = {}

function authReducer (state = initialState, action) {
  if (action.type === AUTH_ACTION.UPDATE_AUTH) {
    // If so, make a copy of `state`
    return {
      ...state,
      ...action.payload
    }
  }

  if (action.type === AUTH_ACTION.UPDATE_USER) {
    let user = getOr({}, 'user')(state)
    user = { ...user, ...action.payload }
    // If so, make a copy of `state`
    return {
      ...state,
      user
    }
  }

  if (action.type === AUTH_ACTION.LOGOUT) {
    return {}
  }
  // otherwise return the existing state unchanged
  return state
}

export default authReducer
