import React from 'react'

import { TELEGRAM_BOT_URL } from '../constants/config'

const TelegramBotUrl = (props) => {
  const { show } = props
  if (!show) return null

  return (
    <div style={{ margin: '10px 0 20px' }}>
      <a href={TELEGRAM_BOT_URL} target='_blank' rel='noreferrer'>Connect to OnlyFlexer Bot</a>
    </div>
  )
}

export default TelegramBotUrl
