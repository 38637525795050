export const ENTITY_TYPE = {
  AUTH: 'auth',
  STATIONS: 'stations',
  OFFERS: 'offers',
  RANGES: 'ranges',
  FEEDBACK: 'feedback',
  SUBSCRIPTION: 'subscription',
  DAYS: 'days',
  SCHEDULED_OFFERS: 'scheduled-offers',
  BOT_SCHEDULES: 'bot-schedules'
}
