import React, { useEffect } from 'react'
import { Button, Typography } from 'antd'
import { connect } from 'react-redux'
import { Crisp } from 'crisp-sdk-web'

import SimpleBox from '../../ui/simple-box'
import { useNavigate } from 'react-router'
import { URLS } from '../../constants'
import withAuthMonitor from './with-auth-monitor'
import { logOut } from '../../data/auth/actions'
import { LargeButton } from '../../ui/button'
import { StyledHeader } from './auth-container'

const AuthLogout = (props) => {
  const { onClear } = props
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    Crisp.setTokenId() // 1. Clear the token value
    Crisp.session.reset()
    onClear()
  }, [])

  return (
    <SimpleBox>
      <StyledHeader>Logged Out</StyledHeader>
      <p style={{ textAlign: 'center', margin: '15px 0 30px' }}>
        <Typography.Text>
          You have been logged out of your OnlyFlexer account. Click below if
          you would like to login again.
        </Typography.Text>
      </p>

      <LargeButton
        block
        onClick={() => navigate(URLS.AUTH_LOGIN)}
        type="primary"
        style={{ marginTop: '20px' }}
      >
        Log In Again
      </LargeButton>
    </SimpleBox>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClear: () => dispatch(logOut())
  }
}

export default connect(null, mapDispatchToProps)(withAuthMonitor(AuthLogout))
