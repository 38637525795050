import { Select } from 'antd'
import React from 'react'
import { TIMEZONE_CHOICES } from '../../constants'

const TimezoneSelect = (props) => {
  const { handleSelect, value, ...restProps } = props

  return (
    <div>
      <Select onSelect={handleSelect} value={value} options={TIMEZONE_CHOICES} style={{ marginTop: '15px', width: '100%' }} showSearch {...restProps}/>
    </div>
  )
}

export default TimezoneSelect
