import React from 'react'
import { Card, Collapse, Divider, InputNumber, Space, Switch, Tag } from 'antd'
import styled from 'styled-components'
import { debounce, filter, getOr } from 'lodash/fp'

import { COLORS } from '../../constants/styles'

const StyledCard = styled(Card)`
  box-shadow: 0 0 1px 1px ${COLORS.ORANGE_FLEX};
  margin: 15px 0;

  .ant-card-head {
    background-color: ${COLORS.ORANGE_LIGHT};
  }
  .ant-card-body:empty {
    display: none;
  }
  .ant-card-body {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
`

const StationItem = (props) => {
  const { canEdit, isActive, station, updateStation } = props

  const handleUpdateStation = debounce(500)((data) => {
    const stationId = station.station_id
    return updateStation(stationId, data)
  })

  return (
    <StyledCard
      title={<p style={{ fontWeight: 'bold' }}>{station.station_name}</p>}
      extra={
        <Switch
          disabled={!canEdit}
          checked={station.is_instant_active}
          onChange={(checked) =>
            handleUpdateStation({ is_instant_active: checked })
          }
        />
      }
    >
      {isActive && (
        <Space style={{ marginTop: 0 }}>
          <p>Distance to Station</p>
          <InputNumber
            disabled={!canEdit}
            suffix="meters"
            style={{ width: '150px' }}
            onChange={(value) =>
              handleUpdateStation({ distance_to_station: value })
            }
            defaultValue={getOr(0)('distance_to_station')(station)}
          />
        </Space>
      )}
    </StyledCard>
  )
}

const InstantOfferStationList = (props) => {
  const { canEdit, stations, updateStation } = props

  const activeStationListItems = filter('is_instant_active')(stations).map(
    (station) => (
      <StationItem
        canEdit={canEdit}
        station={station}
        updateStation={updateStation}
        key={station.station_id}
        isActive={station.is_instant_active}
      />
    )
  )

  const inactiveStationListItems = filter(
    (station) => !station.is_instant_active
  )(stations).map((station) => (
    <StationItem
      canEdit={canEdit}
      station={station}
      updateStation={updateStation}
      key={station.station_id}
    />
  ))

  const activeStationCount = stations.filter(
    (station) => station.is_instant_active
  ).length

  return (
    <Collapse
      items={[
        {
          label: (
            <span>
              Selected Locations <Tag color="success">{activeStationCount}</Tag>
            </span>
          ),
          children: (
            <div>
              {activeStationListItems}
              <Divider />
              {inactiveStationListItems}
            </div>
          )
        }
      ]}
    />
  )
}

export default InstantOfferStationList
