import React from 'react'
import { Tabs } from 'antd'

import AcceptedOfferTable from './accepted-offer-table'
import RunningUserTable from './running-user-table'
import TaskTable from './task-table'

const MonitorContainer = (props) => {
  const items = [
    {
      key: 'runningUsers',
      label: 'Running Users',
      children: <RunningUserTable />
    },
    {
      key: 'acceptedOffers',
      label: 'Accepted Offers',
      children: <AcceptedOfferTable />
    },
    {
      key: 'celeryTasks',
      label: 'Celery Tasks',
      children: <TaskTable />
    }
  ]

  return (
    <div>
      <Tabs items={items} />
    </div>
  )
}

export default MonitorContainer
