import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import { connect } from 'react-redux'

import { DAYS } from '../../constants'
import DayRange from './day-range'
import { listDays, updateDay } from '../../data/day/actions'
import { isDayEnabled } from '../../data/day/selectors'
import { COLORS, media } from '../../constants/styles'
import { styled } from 'styled-components'

const DayWrap = styled.div`
  ${media.smallOnly`
    .ant-tabs-tab {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .ant-tabs-tabpane {
      padding-left: 10px !important;
    }
    .ant-picker-range {
      width: 200px !important;
    }
  `}

  .ant-tabs-tab-active {
    span {
      color: ${COLORS.ORANGE_FLEX} !important;
    }
  }
`

const SettingDays = (props) => {
  const { isEnabled, onInit, updateDay } = props

  useEffect(() => {
    onInit()
  }, [])

  const tabItems = Object.values(DAYS).map((day) => {
    const enabled = isEnabled(day)

    return {
      label: <span style={{ textTransform: 'capitalize', fontWeight: `${enabled ? 'bold' : 'normal'}`, color: `${enabled ? COLORS.BLACK : COLORS.GRAY_2}` }}>{day.substring(0, 3)}</span>,
      key: day,
      children: <DayRange enabled={enabled} day={day} updateDay={updateDay} />
    }
  })

  return (
    <DayWrap>
      <Tabs items={tabItems} tabPosition='left' />
    </DayWrap>
  )
}

const mapStateToProps = (state) => {
  return {
    isEnabled: (day) => isDayEnabled(state, day)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(listDays()),
    updateDay: (day, data) => dispatch(updateDay(day, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingDays)
