import React from 'react'
import { Card, Col, InputNumber, Row, Switch } from 'antd'
import { debounce } from 'lodash/fp'
import styled from 'styled-components'
import { COLORS } from '../../constants/styles'

const StyledCard = styled(Card)`
  box-shadow: 0 0 1px 1px ${COLORS.ORANGE_FLEX};
  margin: 15px 0;
  
  .ant-card-head {
    background-color: ${COLORS.ORANGE_LIGHT};
  }
  .ant-card-body:empty {
    display: none;
  }
`

const StyledInputNumber = styled(InputNumber)`
  width: auto;
`

const StationCard = ({ station, updateStation }) => {
  const handleUpdateStation = debounce(500)((key, value) => {
    const stationId = station.station_id
    const data = {
      [key]: value
    }

    return updateStation(stationId, data)
  })

  const isActive = station.is_active

  return (
    <StyledCard
      title={<p style={{ fontWeight: 'bold' }}>{station.station_name}</p>}
      extra={<Switch checked={station.is_active} onChange={(check) => handleUpdateStation('is_active', check)} />}
    >
      {isActive && (
        <Row>
          <Col xs={12} sm={12}>
            <p>Min Rate ($)</p>
            <StyledInputNumber
              prefix='$'
              suffix='/ hr'
              placeholder='Min Rate'
              style={{ maxWidth: '150px' }}
              onChange={(value) => handleUpdateStation('min_rate', value)}
              defaultValue={station.min_rate}
            />
          </Col>
          <Col xs={12} sm={12}>
            <p>Arrival Time</p>
            <StyledInputNumber
              suffix='mins'
              style={{ maxWidth: '150px' }}
              onChange={(value) => handleUpdateStation('arrival_time', value)}
              defaultValue={parseInt(station.arrival_time)}
            />
          </Col>
        </Row>
      )}
    </StyledCard>
  )
}

export default StationCard
