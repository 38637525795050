import React, { useEffect, useState } from 'react'
import { RedoOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Select, Table, Tag } from 'antd'
import orderBy from 'lodash/fp/orderBy'
import { connect } from 'react-redux'
import moment from 'moment'

import { getFeedback } from '../../data/feedback/selectors'
import { getTasks, terminateTask } from '../../data/monitor/actions'
import { TASK_STATUS, TASK_STATUS_OPTIONS } from './constants'

const FB_FETCH_TASKS = 'fbFetchTasks'
const FB_TERMINATE_TASK = 'fbTerminateTask'

const TaskTable = (props) => {
  const { feedback, feedbackTerminate, onInit, terminate } = props

  const [tasks, setTasks] = useState({})
  const [status, setStatus] = useState(TASK_STATUS.SCHEDULED)

  const handleStatusChange = (value) => setStatus(value)

  const handleFetch = () => onInit({ status }).then((tasks) => setTasks(tasks))
  const handleTerminate = (id) => terminate({ id }).then(() => handleFetch())

  useEffect(() => {
    handleFetch()
  }, [status])

  const columns = [
    {
      title: 'Task ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Running',
      dataIndex: 'running_status',
      key: 'running_status',
      render: (runningStatus) => <Tag color={runningStatus ? 'green' : 'red'}>{runningStatus ? 'Yes' : 'No'}</Tag>
    },
    {
      title: 'Time Start',
      dataIndex: 'time_start',
      key: 'time_start',
      render: (timeStart) => <span>{moment.unix(timeStart).calendar()}</span>
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <Popconfirm title='Sure to terminate?' onConfirm={() => handleTerminate(record.id)}>
          <Button danger loading={feedbackTerminate.isLoading}>Terminate</Button>
        </Popconfirm>
      )
    }
  ]

  const dataSource = orderBy(['email'])(['desc'])(Object.values(tasks)).map((offer) => ({ ...offer, key: offer.id }))

  return (
    <div>
      <Select
        options={TASK_STATUS_OPTIONS}
        value={status}
        onChange={handleStatusChange}
        style={{ width: '170px', marginRight: '15px' }}
      />

      <Button loading={feedback.isLoading} onClick={handleFetch} icon={<RedoOutlined />}>
        Refresh
      </Button>

      <Tag color='green' style={{ float: 'right', margin: '1.5rem 0', fontSize: '1rem' }}>
        {dataSource.length} Running Tasks
      </Tag>

      <Table columns={columns} dataSource={dataSource} loading={feedback.isLoading} pagination={{ pageSize: 100 }} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state, FB_FETCH_TASKS),
    feedbackTerminate: getFeedback(state, FB_TERMINATE_TASK)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: (params) => dispatch(getTasks(params, { feedbackKey: FB_FETCH_TASKS })),
    terminate: (data) => dispatch(terminateTask(data, { feedbackKey: FB_TERMINATE_TASK }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskTable)
