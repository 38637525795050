import axiosInstance from '../config'
import {
  handleError,
  setFeedback
} from '../feedback/actions'

export const getRunningSessions =
  (options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.get('/logs/running_sessions')
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }

export const getRunningSessionOffers =
  (runningSessionId, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.get('/logs/offers', {
          params: {
            running_session_id: runningSessionId
          }
        })
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }
