import React from 'react'
import { Button, Popconfirm, Table, Tag } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { styled } from 'styled-components'
import { SUBSCRIPTION_STATUS } from '../../constants'

const StyledTable = styled(Table)`
  .ant-table-cell {
    background-color: transparent !important;
  }
`

const PaymentMethodList = (props) => {
  const { onDelete, onSetDefault, paymentMethods, subscription } = props

  const canDelete = (pm) => !pm.default || subscription.cancel_at_period_end || (subscription?.status !== SUBSCRIPTION_STATUS.ACTIVE && subscription?.status !== SUBSCRIPTION_STATUS.PAST_DUE)

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, pm) => <span style={{ textTransform: 'capitalize' }}>{pm.card_type}</span>
    },
    {
      title: 'Last Four',
      dataIndex: 'last_four',
      key: 'last_four',
      render: (_, pm) => (
        <>
          {pm.last_four}
          {pm.default && <Tag style={{ marginLeft: '5px' }}>Default</Tag>}
        </>
      )
    },
    {
      title: 'Expires',
      dataIndex: 'expires',
      key: 'expires',
      render: (_, pm) => (
        <>
          {pm.exp_month} / {pm.exp_year}
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, pm) => {
        return (
          canDelete(pm) && <>
            <Popconfirm
              title='Are you sure?'
              description='This action cannot be undone.'
              onConfirm={() => onDelete(pm.pk)}
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
            {!pm.default && <Button onClick={() => onSetDefault(pm.pk)} style={{ marginLeft: '5px' }}>Set to Default</Button>}
                           </>
        )
      }
    }
  ]

  const data = Object.values(paymentMethods)

  return <StyledTable pagination={false} dataSource={data} columns={columns} />
}

export default PaymentMethodList
