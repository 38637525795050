import React, { useState } from 'react'
import { Button } from 'antd'

import BotScheduleItem from './bot-schedule-item'

const DEFAULT_SCHEDULE = {
  start_time: '00:00',
  end_time: '23:59',
}

const BotScheduleList = (props) => {
  const { handleChange, schedules } = props
  const [loading, setLoading] = useState(false)

  const handleOnClick = () => {
    setLoading(true)
    setTimeout(() => {
      let newSchedules = [...schedules, DEFAULT_SCHEDULE]
      handleChange('schedules', newSchedules)
      setLoading(false)
    }, 500)
  }

  const handleRemove = (index) => {
    schedules.splice(index, 1)
    handleChange('schedules', [...schedules])
  }

  const handleUpdate = (index, schedule) => {
    const newSchedules = [...schedules];
    newSchedules[index] = schedule;
    handleChange('schedules', newSchedules)
  }

  return (
    <div>
      {schedules.map((schedule, index) => {
        return <BotScheduleItem key={schedule.pk} schedule={schedule} handleRemove={handleRemove} index={index} handleUpdate={handleUpdate}/>
      })}
      <Button
          style={{ marginTop: '15px' }}
          type="primary"
          onClick={handleOnClick}
          loading={loading}
      >
        Add new schedule
      </Button>
    </div>
  )
}

export default BotScheduleList
