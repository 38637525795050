import React from 'react'
import { Badge, Button, Card } from 'antd'
import styled from 'styled-components'

import { COLORS } from '../../constants/styles'
import { LargeButton } from '../../ui/button'

const CardWrap = styled.div`
  width: 250px;
  margin: 0 10px;
`

const StyledCard = styled(Card)`
  text-align: center;

  .ant-card-head {
    background-color: ${COLORS.GRAY};
  }

  .ant-card-head-title {
    font-weight: bold;
  }

  .value {
    font-size: 2.5rem !important;
  }

  .label {
    color: rgba(0, 0, 0, 0.4) !important;
  }
`

const PlanItem = (props) => {
  const { plan, planId, validatePlan } = props

  const handleSelect = () => validatePlan(planId)

  const metadata = JSON.parse(plan.metadata)

  return (
    <CardWrap>
      {metadata.extra
        ? (
          <Badge.Ribbon text={metadata.extra}>
            <StyledCard title={metadata.title}>
              <p className='value'>${metadata.value}</p>
              <p className='label'>{metadata.label}</p>
              <LargeButton style={{ marginTop: '15px' }} type='primary' onClick={handleSelect}>
                Select Plan
              </LargeButton>
            </StyledCard>
          </Badge.Ribbon>
          )
        : (
          <StyledCard title={metadata.title}>
            <p className='value'>${metadata.value}</p>
            <p className='label'>{metadata.label}</p>
            <LargeButton style={{ marginTop: '15px' }} type='primary' onClick={handleSelect}>
              Select Plan
            </LargeButton>
          </StyledCard>
          )}
    </CardWrap>
  )
}

export default PlanItem
