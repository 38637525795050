import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { getCurrentSubscription } from '../../data/subscription/selectors'

import Message from '../../ui/message'
import { REGIONS, SUBSCRIPTION_STATUS, URLS } from '../../constants'
import { getAuthUser } from '../../data/auth/selectors'
import { getOr } from 'lodash/fp'

const withSubscription = (Component) => {
  const mapStateToProps = (state) => {
    return {
      user: getAuthUser(state),
      subscription: getCurrentSubscription(state)
    }
  }

  return connect(mapStateToProps)((props) => {
    const { subscription, user } = props
    const expired =
      subscription?.status === SUBSCRIPTION_STATUS.CANCELLED ||
      subscription?.status === SUBSCRIPTION_STATUS.PAST_DUE ||
      moment(subscription.end_date).isBefore(moment.now())

    let isFree = false
    const region = getOr(null, 'region')(user)
    if (process.env.REACT_APP_ALLOW_FREE_FOR_EU && region === REGIONS.EU) {
      isFree = true
    } else if (process.env.REACT_APP_ALLOW_FREE_FOR_ASIA && region === REGIONS.ASIA) {
      isFree = true
    }

    if (expired && !isFree) {
      return (
        <Link to={URLS.SUBSCRIPTION}>
          <Message
            title="Your subscription has expired"
            content="You can click here to manage your subscription"
            warning
          />
        </Link>
      )
    }

    return <Component {...props} />
  })
}

export default withSubscription
