import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { URLS } from '../../constants'

const withAuthMonitor = (Component) => {
  return (props) => {
    const navigate = useNavigate()
    const location = useLocation()

    const pathname = location.pathname

    const refreshToken = localStorage.getItem('refresh_token')

    useEffect(() => {
      if (pathname === URLS.AUTH_START || pathname === URLS.AUTH_LOGIN || pathname === URLS.AUTH_SIGNUP || pathname === URLS.AUTH_FORGOT_PASSWORD) {
        if (refreshToken) {
          navigate(URLS.DASHBOARD)
        }
      } else if (pathname !== URLS.AUTH_LOGOUT) {
        if (!refreshToken) {
          navigate(URLS.AUTH_START)
        }
      }
    }, [pathname, refreshToken, navigate])

    return <Component {...props} />
  }
}

export default withAuthMonitor
