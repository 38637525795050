import { Button, Card, Modal } from 'antd'
import React from 'react'
import { styled } from 'styled-components'
import { formattedCurrency } from './helpers'
import PaymentMethodForm from './payment-method-form'
import { LargeButton } from '../../ui/button'

const StyledCard = styled(Card)`
  text-align: center;
  margin: 10px 0;

  .ant-card-body {
    padding: 12px 24px;
  }

  h3 {
    font-weight: bold;
  }
`

const PlanChangeConfirm = (props) => {
  const { feedback, openConfirm, onClosePlanDialog, onClose, onChangePlan, quote } = props

  if (!quote) return null

  const handleChangePlan = (payment_method_id = null) => {
    const data = {
      plan_id: quote.plan_id,
      stripe_price_id: quote.stripe_price_id,
      payment_method_id
    }

    return onChangePlan(data).then(() => {
      onClose()
      onClosePlanDialog()
    })
  }

  return (
    <Modal open={openConfirm} onCancel={onClose} footer={[]} centered destroyOnClose>
      <p>Your subscription will be changed to:</p>
      <StyledCard>
        <h3>{quote.name}</h3>
      </StyledCard>
      <p>
        You will be charged <strong>{formattedCurrency(quote.recurring_total)}</strong> today and your recurring{' '}
        {quote.interval} charge will be <strong>{formattedCurrency(quote.recurring_total)}</strong>
      </p>

      {quote.needs_payment_method && (
        <>
          <h3 style={{ margin: '15px 0 5px' }}>Your Payment Information</h3>
          <PaymentMethodForm
            buttonProps={{ block: true, loading: feedback.isLoading, style: { marginTop: '20px' } }}
            buttonLabel='Confirm Plan Change'
            onConfirm={handleChangePlan}
          />
        </>
      )}

      {!quote.needs_payment_method && (
        <LargeButton loading={feedback.isLoading} block onClick={() => handleChangePlan()} type='primary' style={{ marginTop: '20px' }}>
          Confirm Plan Change
        </LargeButton>
      )}
    </Modal>
  )
}

export default PlanChangeConfirm
