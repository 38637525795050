import { AUTH_ACTION } from '../auth/constants'
import { DAY_ACTION, RANGE_ACTION } from './constants'

const initialState = {}

export function rangeReducer (state = initialState, action) {
  if (action.type === RANGE_ACTION.LIST) {
    return {
      ...state,
      ...action.payload
    }
  }

  if (action.type === RANGE_ACTION.REMOVE) {
    const newState = { ...state }
    delete newState[action.payload]
    return newState
  }

  if (action.type === RANGE_ACTION.CREATE) {
    return {
      ...state,
      ...action.payload
    }
  }

  if (action.type === AUTH_ACTION.LOGOUT) {
    return {}
  }

  // otherwise return the existing state unchanged
  return state
}

export function dayReducer (state = initialState, action) {
  if (action.type === DAY_ACTION.LIST) {
    return {
      ...state,
      ...action.payload
    }
  }

  if (action.type === DAY_ACTION.UPDATE) {
    return {
      ...state,
      ...action.payload
    }
  }

  if (action.type === AUTH_ACTION.LOGOUT) {
    return {}
  }

  // otherwise return the existing state unchanged
  return state
}
