import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Space, Spin, Statistic } from 'antd'
import getOr from 'lodash/fp/getOr'
import { connect } from 'react-redux'

import { listStations, updateStation } from '../../data/station/actions'
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'
import InstantOfferStationList from './instant-offer-station-list'
import {
  getInstantOfferLastLocation,
  startInstantOfferSearching,
  stopInstantOfferSearching
} from '../../data/search/actions'
import { getAuthUser } from '../../data/auth/selectors'
import Poller from '../poller'
import DashboardOnboard from '../dashboard/dashboard-onboard'
import { getFeedback } from '../../data/feedback/selectors'
import { submitAmzUrl } from '../../data/auth/actions'
import { REGIONS } from '../../constants'
import InstantOfferInstruction from './instant-offer-instruction'
import { LargeButton } from '../../ui/button'

export const FB_KEY_SUBMIT_AMZ_URL = 'fbSubmitAmzUrl'

const InstantOfferContainer = (props) => {
  const {
    fetchStations,
    getInstantOfferLastLocation,
    startInstantOfferSearching,
    stations,
    stopInstantOfferSearching,
    updateStation,
    user,
    feedback,
    submitUrl
  } = props

  const [lastLocation, setLastLocation] = useState('')

  const isInstantOfferSearching = getOr(false, 'is_instant_offer_running')(user)

  useEffect(() => {
    fetchStations()
  }, [])

  const handleGetLastLocation = () => getInstantOfferLastLocation().then(location => setLastLocation(location))

  const amzVerify = getOr(false, 'amz_verify')(user)

  const region = getOr(null, 'region')(user)

  const instantOffersActive = region === REGIONS.NA

  if (!instantOffersActive) return null

  if (!amzVerify) return <DashboardOnboard feedback={feedback} user={user} submitUrl={submitUrl} />

  return (
    <Row>
      <Poller baseTimeoutMs={20000} exponentialBase={1} maxRetries={null} onPoll={handleGetLastLocation} isFinished={!isInstantOfferSearching} />

      <Col xs={24} md={16}>
        <h2 style={{ marginBottom: '40px' }}>Instant Offer</h2>

        <Space direction='vertical' style={{ marginBottom: '20px' }}>
          {isInstantOfferSearching
            ? (
              <>
                <Spin tip='Running Instant Offer Search' size='large'>
                  <div className='content' />
                </Spin>
                <LargeButton
                  onClick={() => stopInstantOfferSearching()}
                  type='primary'
                  style={{ marginTop: '2rem', padding: '0 50px'}}
                >
                  Stop
                </LargeButton>
              </>
              )
            : (
              <LargeButton
                type='primary'
                style={{ padding: '0 50px' }}
                onClick={() => startInstantOfferSearching()}
              >
                Start
              </LargeButton>
              )}
        </Space>

        {isInstantOfferSearching && (
          <Statistic style={{ marginBottom: '10px' }} title='Last Location Sent' value={lastLocation} />
        )}

        <InstantOfferInstruction />

        <InstantOfferStationList
          stations={stations}
          updateStation={updateStation}
          canEdit={!isInstantOfferSearching}
        />
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    stations: selectors.list(state, ENTITY_TYPE.STATIONS),
    user: getAuthUser(state),
    feedback: getFeedback(state, FB_KEY_SUBMIT_AMZ_URL)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStations: () => dispatch(listStations()),
    updateStation: (stationId, data) =>
      dispatch(updateStation(stationId, data)),
    startInstantOfferSearching: () => dispatch(startInstantOfferSearching()),
    stopInstantOfferSearching: () => dispatch(stopInstantOfferSearching()),
    getInstantOfferLastLocation: () => dispatch(getInstantOfferLastLocation()),
    submitUrl: (data) => dispatch(submitAmzUrl(data, { feedbackKey: FB_KEY_SUBMIT_AMZ_URL }))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstantOfferContainer)
