import React from 'react'
import styled from 'styled-components'
import { ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons'

import { COLORS } from '../constants/styles'

const MessageWrap = styled.div`
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 3px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 0 1px ${COLORS.YELLOW_WARNING} inset, 0 0 0 0 transparent;
  border-color: transparent;
  border-radius: 4px;
  background-color: ${COLORS.YELLOW_WARNING_BG};

  .anticon svg {
    font-size: 30px;
    margin-right: 10px;
  }

  .title {
    color: ${COLORS.BROWN_WARNING_TITLE};
    font-weight: bold;
  }

  .content {
    color: ${COLORS.BROWN_WARNING_TEXT};
  }

  ${(props) =>
    props.warning &&
    `
        .anticon svg {
            color: ${COLORS.ORANGE_FLEX};
        }
    `}

  ${(props) =>
    props.danger &&
    `
        .anticon svg {
            color: ${COLORS.RED};
        }
    `}
`

const Message = (props) => {
  const { danger, title, content, warning } = props
  return (
    <MessageWrap warning={warning} danger={danger}>
      {warning && <ExclamationCircleFilled />}
      {danger && <WarningOutlined />}
      <div>
        <p className='title'>{title}</p>
        <p className='content'>{content}</p>
      </div>
    </MessageWrap>
  )
}

export default Message
