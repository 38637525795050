import axiosInstance from '../config'
import { handleError, setFeedback, setSuccessFeedback } from '../feedback/actions'

export const getRunningUsers =
  (options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.get('/monitor/get_running_users')
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }

export const getAcceptedOffers =
  (options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.get('/monitor/get_accepted_offers')
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }

export const restartSearch =
  (email, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.put(`/monitor/restart_search/${email}`)
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        if (options.feedbackToGeneral) {
          dispatch(setSuccessFeedback(options.feedbackToGeneral))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }

export const restartAllSearches =
  (email, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.put('/monitor/restart_all_searches')
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        if (options.feedbackToGeneral) {
          dispatch(setSuccessFeedback(options.feedbackToGeneral))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }

export const getTasks =
  (params, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const queryParams = new URLSearchParams(params).toString()

        const response = await axiosInstance.put(`/monitor/get_tasks?${queryParams}`)
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        if (options.feedbackToGeneral) {
          dispatch(setSuccessFeedback(options.feedbackToGeneral))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }

export const terminateTask =
  (data, options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.put('/monitor/terminate_task', data)
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        if (options.feedbackToGeneral) {
          dispatch(setSuccessFeedback(options.feedbackToGeneral))
        }

        return responseData
      } catch (error) {
        handleError(error, dispatch)

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }
