import { Route } from 'react-router-dom'

import DashboardLayout from './dashboard-layout'
import AuthForgetPassword from '../modules/auth/auth-forget-password'
import AuthLogin from '../modules/auth/auth-login'
import AuthSignUp from '../modules/auth/auth-signup'
import AuthLogout from '../modules/auth/auth-logout'
import { URLS } from '../constants'
import AuthContainer from '../modules/auth/auth-container'

const routers = (
  <>
    <Route path={URLS.AUTH_START} Component={AuthContainer} />
    <Route path={URLS.AUTH_LOGOUT} Component={AuthLogout} />
    <Route path={URLS.AUTH_LOGIN} Component={AuthLogin} />
    <Route path={URLS.AUTH_SIGNUP} Component={AuthSignUp} />
    <Route path={URLS.AUTH_FORGOT_PASSWORD} Component={AuthForgetPassword} />
    <Route path='/*' Component={DashboardLayout} />
  </>
)

export default routers
