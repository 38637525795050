export const FEEDBACK_ACTION = {
  SET_FEEDBACK: 'feedback/SET',
  SET_GENERAL: 'feedback/general/SET',
  CLEAR_GENERAL: 'feedback/general/CLEAR',
  CLEAR_FEEDBACK: 'feedback/CLEAR'
}

export const GENERAL_FEEDBACK_KEY = 'fbGeneral'

export const FEEDBACK_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success'
}
