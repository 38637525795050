import React, { useState } from 'react'
import Message from '../../ui/message'
import { Button, Col, Divider, Row, Select, Tooltip } from 'antd'
import TimezoneSelect from './timezone-select'
import { ExclamationCircleTwoTone } from '@ant-design/icons'
import { pick } from 'lodash/fp'
import { COLORS } from '../../constants/styles'
import { updateUserSettings } from '../../data/auth/actions'
import { connect } from 'react-redux'
import { getAuthUser } from '../../data/auth/selectors'
import { REGION_CHOICES } from '../../constants'
import { LargeButton } from '../../ui/button'

const SettingRegion = (props) => {
  const { user, onSubmit } = props

  const initialValues = pick(['region', 'timezone_id'])(user)

  const [form, setForm] = useState(initialValues)

  const { region, timezone_id } = form

  const handleChange = (key, value) => setForm({ ...form, [key]: value })

  const handleSubmit = () => onSubmit(form)
  return (
    <Row>
      <Col xs={24} md={12}>
        <Message title='Danger Zone' content='Proceed with caution' danger />

        <h2 style={{ display: 'inline-block', fontWeight: 'bold', margin: '20px 0 10px' }}>
          Region
        </h2>
        <Select options={REGION_CHOICES} onSelect={(value) => handleChange('region', value)} style={{ display: 'block', width: '100%' }} value={region} />

        <Tooltip placement='right' title='Changing to incorrect timezone might affect your day ranges.'>
          <h2 style={{ display: 'inline-block', fontWeight: 'bold', marginTop: '20px' }}>
            Time Zone <ExclamationCircleTwoTone twoToneColor={COLORS.RED} />
          </h2>
        </Tooltip>
        <TimezoneSelect handleSelect={(value) => handleChange('timezone_id', value)} value={timezone_id} />
        <Divider />

        <LargeButton style={{ marginTop: '20px', padding: '0 50px' }} type='primary' onClick={handleSubmit}>
          Save
        </LargeButton>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    user: getAuthUser(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (data) => dispatch(updateUserSettings(data, { feedbackToGeneral: 'Saved' }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingRegion)
