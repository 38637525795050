import { AUTH_ACTION } from '../auth/constants'
import { SUBSCRIPTION_ACTION } from './constants'

const initialState = {}

function subscriptionReducer (state = initialState, action) {
  if (action.type === SUBSCRIPTION_ACTION.UPDATE_SUBSCRIPTION) {
    return { ...action.payload }
  }

  if (action.type === AUTH_ACTION.LOGOUT) {
    return {}
  }
  // otherwise return the existing state unchanged
  return state
}

export default subscriptionReducer
