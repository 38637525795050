import React from 'react'
import TimePicker from 'react-time-picker'
import styled from 'styled-components'

import 'react-time-picker/dist/TimePicker.css'
import { COLORS } from '../constants/styles'

const StyledTimePicker = styled(TimePicker)`
    .react-time-picker__wrapper {
        border-radius: 4px;
        padding: 7px 10px;
        border-color: ${COLORS.ORANGE_FLEX}
    }
`

// Custom time picker component
const TimePickerWithoutSeconds = (props) => {
  return (
    <StyledTimePicker
      disableClock // This disables the clock (seconds) part
      format='h:mm a' // Customize the format as needed
      {...props}
    />
  )
}

export default TimePickerWithoutSeconds
