import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import { connect } from 'react-redux'

import OfferList from '../dashboard/offer-list'
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'
import { listStations } from '../../data/station/actions'
import { listOffers } from '../../data/offer/actions'
import { OFFER_STATUS } from '../../constants'
import { offersByStatus } from '../../data/offer/selectors'

const HistoryContainer = (props) => {
  const { onInit, acceptedOffers, stations } = props

  useEffect(() => {
    onInit()
  }, [])

  return (
    <Row>
      <Col xs={24} md={16}>
        <h2 style={{ marginBottom: '40px' }}>History</h2>
        <OfferList offers={acceptedOffers} stations={stations} />
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    stations: selectors.list(state, ENTITY_TYPE.STATIONS),
    acceptedOffers: offersByStatus(state, OFFER_STATUS.ACCEPTED)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(listStations()).then(() => dispatch(listOffers({ status: OFFER_STATUS.ACCEPTED })))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer)
