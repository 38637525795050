import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import moment from 'moment'

import OfferCardV1 from '../dashboard/offer-card-v1'
import OfferList from '../dashboard/offer-list'
import styled from 'styled-components'
import { getOr, isEmpty } from 'lodash/fp'
import { LoadingOutlined } from '@ant-design/icons'
import Message from '../../ui/message'

const StyledSpin = styled(Spin)`
  .ant-spin-text {
    margin-top: 20px;
  }
`

const OfferLogList = (props) => {
  const { feedback, listRunningSessionOffers, runningSession, stations } = props

  const [offers, setOffers] = useState([])

  useEffect(() => {
    if (runningSession) {
      listRunningSessionOffers(runningSession).then((offers) =>
        setOffers(Object.values(offers))
      )
    }
  }, [runningSession])

  if (!runningSession) return null

  const offerWithRate = offers.map((offer) => {
    const startTime = moment.unix(offer.start_time)
    const endTime = moment.unix(offer.end_time)

    const minutesDifference = endTime.diff(startTime, 'minutes')
    const rate = (offer.block_rate * 60) / minutesDifference
    offer.pay_rate = rate

    return offer
  })

  const isLoading = getOr(false, 'isLoading')(feedback)

  return isLoading
    ? (
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <StyledSpin
          indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />}
          tip='Loading...'
          size='large'
        >
          <div className='content' />
        </StyledSpin>
      </div>
      )
    : (
      <div>
        {!isLoading && isEmpty(offerWithRate) && <Message title='No events' />}
        {!isEmpty(offerWithRate) && (
          <OfferList
            offers={offerWithRate}
            stations={stations}
            hideCaptchaRequired
            hideReason
          />
        )}
      </div>
      )
}

export default OfferLogList
