export const TASK_STATUS = {
  ACTIVE: 'active',
  SCHEDULED: 'scheduled',
  RESERVED: 'reserved',
  REGISTERED: 'registered',
  DANGLING: 'dangling'
}

export const TASK_STATUS_COLOR = {
  [TASK_STATUS.ACTIVE]: 'green',
  [TASK_STATUS.SCHEDULED]: 'blue',
  [TASK_STATUS.DANGLING]: 'red'
}

export const TASK_STATUS_OPTIONS = [
  { label: 'Active', value: TASK_STATUS.ACTIVE },
  { label: 'Scheduled', value: TASK_STATUS.SCHEDULED }
]
