import React from 'react'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import moment from 'moment'
import { connect } from 'react-redux'
import { CoffeeOutlined, LoadingOutlined } from '@ant-design/icons'

import { SEARCH_PATTERN } from '../../constants'
import { getAuthUser } from '../../data/auth/selectors'
import withSubscription from '../subscription/with-subscription'

const SearchRunningStatus = (props) => {
  const { user } = props

  const isRunning = getOr(false, 'is_running')(user)

  if (isEmpty(user) || !isRunning) return null

  let spinTip = 'Searching...'

  const searchPattern = getOr(SEARCH_PATTERN.STANDARD, 'search_pattern')(user)

  const sleepingStartTimestamp = getOr(null, 'sleeping_start_timestamp')(user)
  const sleepingTime = getOr(null, 'sleeping_time')(user)
  const sleepingStartMoment = moment.unix(sleepingStartTimestamp)

  const totalSearchTime = getOr(0, 'total_search_time')(user)
  const searchStartAt = getOr(null, 'search_start_at')(user)

  if (sleepingStartTimestamp) {
    let sleepingEndMoment = sleepingStartMoment
      .clone()
      .add(sleepingTime, 'minutes')
    const currentMoment = moment()

    // STANDARD
    let isWithinInterval = currentMoment.isBetween(
      sleepingStartMoment,
      sleepingEndMoment
    )

    if (searchPattern === SEARCH_PATTERN.ADVANCED) {
      const searchStartAtMoment = moment.unix(searchStartAt)

      sleepingEndMoment = searchStartAtMoment
        .clone()
        .add(totalSearchTime, 'minutes')
      isWithinInterval = sleepingStartMoment.isBetween(
        searchStartAtMoment,
        sleepingEndMoment
      )
    } else if (searchPattern === SEARCH_PATTERN.FIXED_INTERVAL) {
      const searchStartAtMoment = moment.unix(searchStartAt)
      sleepingEndMoment = searchStartAtMoment.clone().add(5, 'minutes')
      isWithinInterval = currentMoment.isBetween(
        sleepingStartMoment,
        sleepingEndMoment
      )
    }

    if (isWithinInterval) {
      spinTip = `Sleeping`
    }
  }
  return (
    <div>
      {spinTip === 'Sleeping' ? (
        <CoffeeOutlined style={{ marginRight: '10px' }}/>
      ) : (
        <LoadingOutlined style={{ marginRight: '10px' }}/>
      )}
      {spinTip}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: getAuthUser(state)
  }
}

export default withSubscription(connect(mapStateToProps)(SearchRunningStatus))
