import { getOr } from 'lodash/fp'
import { getAuthUser } from '../auth/selectors'
import { ENTITY_TYPE } from '../entities/constants'
import { selectors } from '../selectors'

export const offersByStatus = (state, status) => {
  const allOffers = selectors.list(state, ENTITY_TYPE.OFFERS)
  return allOffers.filter(offer => offer.status === status)
}

export const currentSessionOffers = (state) => {
  const user = getAuthUser(state)
  const currentRunningSession = getOr(null, 'current_running_session')(user)
  const allOffers = selectors.list(state, ENTITY_TYPE.OFFERS)

  return allOffers.filter(offer => offer.running_session === currentRunningSession)
}
