import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { connect } from 'react-redux'
import { styled } from 'styled-components'

import PlanItem from './plan-item'
import { getDefaultPaymentMethod, listPaymentMethods, listPlans, updatePlan } from '../../data/billing/actions'
import PlanChangeConfirm from './plan-change-confirm'
import { media } from '../../constants/styles'
import { getFeedback } from '../../data/feedback/selectors'
import { retrieveSubscription } from '../../data/subscription/actions'
import { LargeButton } from '../../ui/button'

const FB_KEY_CHANGE_PLAN = 'fbChangePlan'

const PlansWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;

  ${media.smallOnly`
    flex-direction: column;
    align-items: center;
  `}
`

const PlanManagementDialog = (props) => {
  const { feedback, onInit, onChangePlan, validatePlan } = props

  const [isOpen, setIsOpen] = useState(false)
  const [plans, setPlans] = useState({})
  const [quote, setQuote] = useState(null)
  const [openConfirm, setOpenConfirm] = useState(false)

  useEffect(() => {
    onInit().then((plans) => setPlans(plans))
  }, [])

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const handleValidate = (data) =>
    validatePlan(data).then((quote) => {
      setOpenConfirm(true)
      setQuote(quote)
    })

  const handleCloseConfirm = () => setOpenConfirm(false)

  const planItems = Object.keys(plans).map((key) => (
    <PlanItem key={key} plan={plans[key]} planId={key} validatePlan={handleValidate} />
  ))

  return (
    <>
      <LargeButton onClick={handleOpen} type='primary'>
        Manage Subscription
      </LargeButton>
      <Modal
        open={isOpen}
        title='Manage Subscription'
        onCancel={handleClose}
        footer={[]}
        width={1000}
        centered
        destroyOnClose
      >
        <PlansWrap>{planItems}</PlansWrap>

        <PlanChangeConfirm
          feedback={feedback}
          openConfirm={openConfirm}
          onClosePlanDialog={handleClose}
          onClose={handleCloseConfirm}
          onChangePlan={onChangePlan}
          quote={quote}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state, FB_KEY_CHANGE_PLAN)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(listPlans()),
    onChangePlan: (data) =>
      dispatch(updatePlan(data, { feedbackKey: FB_KEY_CHANGE_PLAN }))
        .then(() => dispatch(retrieveSubscription()))
        .then(() => dispatch(listPaymentMethods()))
        .then(() => dispatch(getDefaultPaymentMethod()))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanManagementDialog)
