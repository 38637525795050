import { SCHEDULED_OFFER_ACTION } from './constants'
import { AUTH_ACTION } from '../auth/constants'

const initialState = { }

function scheduledOfferReducer (state = initialState, action) {
  if (action.type === SCHEDULED_OFFER_ACTION.LIST) {
    return {
      ...state,
      ...action.payload
    }
  }

  if (action.type === AUTH_ACTION.LOGOUT) {
    return {}
  }

  return state
}

export default scheduledOfferReducer
