import React from 'react'
import {
  LeftOutlined,
  LockOutlined,
  MailOutlined
} from '@ant-design/icons'
import { Form, Input } from 'antd'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'

import { styled } from 'styled-components'
import { PUBLIC_ASSET_URLS, URLS } from '../../constants'
import { COLORS } from '../../constants/styles'
import { login } from '../../data/auth/actions'
import { LargeButton, LinkButton } from '../../ui/button'
import TallBox from '../../ui/tall-box'
import { StyledHeader } from './auth-container'
import withAuthMonitor from './with-auth-monitor'

const StyledNavLink = styled(NavLink)`
  display: block;
  text-align: center;
  color: ${COLORS.ORANGE_FLEX};
`

export const StyledInput = styled(Input)`
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: none !important;
  padding: 10px 0;

  .anticon {
    display: inline-block;
    margin-right: 10px;
    color: ${COLORS.GRAY_2};
  }

  &:focus-within {
    .anticon {
      color: ${COLORS.ORANGE_FLEX};
    }
  }
`

export const StyledInputPassword = styled(Input.Password)`
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: none !important;
  padding: 10px 0;

  .anticon {
    display: inline-block;
    margin-right: 10px;
    color: ${COLORS.GRAY_2};
  }

  &:focus-within {
    .anticon {
      color: ${COLORS.ORANGE_FLEX};
    }
  }
`

const AuthLogin = (props) => {
  const { login } = props

  const [form] = Form.useForm()

  const navigate = useNavigate()

  const handleLogin = (values) => login(values).then(() => navigate(URLS.DASHBOARD))

  return (
    <TallBox>
      <div>
        <LinkButton to={URLS.AUTH_START} style={{ width: '50px' }}>
          <LeftOutlined />
        </LinkButton>
        <img
          src={PUBLIC_ASSET_URLS.AUTH_PEOPLE}
          alt="login"
          style={{ width: '100%', margin: '0 auto', display: 'block' }}
        />
        <StyledHeader>Welcome back</StyledHeader>

        <Form form={form} onFinish={handleLogin} style={{ marginTop: '20px' }}>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input your email!'
              }
            ]}
          >
            <StyledInput placeholder="Email" prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <StyledInputPassword
              placeholder="Password"
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <LargeButton
            block
            type="primary"
            htmlType="submit"
            style={{ margin: '20px 0' }}
          >
            Submit
          </LargeButton>
        </Form>
      </div>
      <div>
        <StyledNavLink to={URLS.AUTH_FORGOT_PASSWORD}>
          Forgot your password?
        </StyledNavLink>
      </div>
    </TallBox>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data))
  }
}

export default connect(null, mapDispatchToProps)(withAuthMonitor(AuthLogin))
