import React from 'react'
import * as Sentry from '@sentry/react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { ConfigProvider } from 'antd'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import HttpsRedirect from 'react-https-redirect'

import './index.css'
import store from './data/store'
import primaryTheme from './theme/primary'
import routers from './app/routers'
import FeedbackMessageGeneral from './ui/feedback-message-general'
import CrispInit from './services/crisp'

// Sentry.init({
//   dsn: 'https://1f18f3c4977d53cc2652178b41c89303@o4505791010242560.ingest.sentry.io/4506731083792384',
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false
//     })
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/api.onlyflexer\.com\/*/, /^https:\/\/onlyflexer-staging-428f0d92f0d0\.herokuapp.com\/*/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <HttpsRedirect>
      <Provider store={store}>
        <ConfigProvider theme={primaryTheme}>
          <FeedbackMessageGeneral />
          <CrispInit />
          <Elements stripe={stripePromise}>
            <BrowserRouter>
              <Routes>{routers}</Routes>
            </BrowserRouter>
          </Elements>
        </ConfigProvider>
      </Provider>
    </HttpsRedirect>
  </React.StrictMode>
)
