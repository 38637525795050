import { getOr } from 'lodash/fp'

import { ENTITY_TYPE } from '../entities/constants'
import { selectors } from '../selectors'

export const rangesByDay = (state, day) => {
  const allRanges = selectors.list(state, ENTITY_TYPE.RANGES)

  return allRanges.filter(range => range.day === day)
}

export const isDayEnabled = (state, day) => {
  const allDays = selectors.list(state, ENTITY_TYPE.DAYS)

  const dayObj = allDays.find(d => d.day === day)
  if (!dayObj) return true

  return getOr(true, 'enabled')(dayObj)
}
