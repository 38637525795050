import React, { useEffect } from 'react'
import { Col, Empty, Row } from 'antd'
import { connect } from 'react-redux'

import { bypassSelfie, listScheduledOffers, uploadSelfie } from '../../data/scheduled-offer/actions'
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'
import ScheduledOfferCard from './scheduled-offer-card'
import { getFeedback } from '../../data/feedback/selectors'
import { isEmpty } from 'lodash'
import withSubscription from '../subscription/with-subscription'

export const FB_BYPASS_SELFIE = 'fbBypassSelfie'

const ScheduledOffersContainer = (props) => {
  const { feedback, onBypassSelfie, onInit, scheduledOffers, uploadSelfiePhoto } = props

  useEffect(() => {
    onInit()
  }, [])

  const scheduledOfferList = scheduledOffers.map((offer) => (
    <ScheduledOfferCard
      feedback={feedback}
      key={offer.id}
      onBypassSelfie={onBypassSelfie}
      scheduledOffer={offer}
      uploadSelfiePhoto={uploadSelfiePhoto}
    />
  ))

  return (
    <Row>
      <Col xs={24} md={14}>
        <h2>Scheduled Offers</h2>
        {isEmpty(scheduledOfferList)
          ? (
            <Empty description='No Scheduled Offers' />
            )
          : (
            <div>{scheduledOfferList}</div>
            )}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state, FB_BYPASS_SELFIE),
    scheduledOffers: selectors.list(state, ENTITY_TYPE.SCHEDULED_OFFERS)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(listScheduledOffers()),
    onBypassSelfie: (data) => dispatch(bypassSelfie(data, { feedbackKey: FB_BYPASS_SELFIE })),
    uploadSelfiePhoto: (data) => dispatch(uploadSelfie(data))
  }
}

export default withSubscription(connect(mapStateToProps, mapDispatchToProps)(ScheduledOffersContainer))
