import React from 'react'
import { Card } from 'antd'
import styled from 'styled-components'

import { COLORS } from '../../constants/styles'
import { URLS } from '../../constants'
import { useNavigate } from 'react-router'

const StyledCard = styled(Card)`
  // box-shadow: 0 0 1px 1px ${COLORS.GRAY_4};
  margin: 20px 0;
  background-color: transparent;
  border: 1px solid ${COLORS.GRAY_4};

  .ant-card-head {
    background-color: ${COLORS.ORANGE_LIGHT};
    height: 20px;
    position: relative;
    * {
      font-weight: bold;
    }
    border-bottom: 1px solid ${COLORS.GRAY_4};
  }

  .ant-card-head img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .ant-card-head-title {
    display: flex;
    justify-content: space-between;
  }

  .ant-card-body {
    padding: 5px 20px;
    text-align: center;
    font-weight: 500;
    color: ${COLORS.ORANGE_FLEX};
  }
`

const ManageSubscription = (props) => {
  const { handleCloseMenu } = props
  const navigate = useNavigate()

  const navigateToSubscription = () => {
    handleCloseMenu()
    navigate(URLS.SUBSCRIPTION)
  }

  return (
    <div
      style={{ padding: '0 20px', cursor: 'pointer' }}
      onClick={navigateToSubscription}
    >
      <StyledCard
        title={<img src="./assets/images/upgrade.png" alt="Subscription" />}
      >
        Manage Subscription
      </StyledCard>
    </div>
  )
}

export default ManageSubscription
