import isEmpty from 'lodash/fp/isEmpty'
import getOr from 'lodash/fp/getOr'

export const getAuthUser = (state) => {
  const user = { ...getOr({}, 'auth.user')(state) }
  const email = getOr('', 'auth.email')(state)

  if (!isEmpty(user)) {
    user.email = email
  }

  return user
}

export const hasFeature = (user, feature) => {
  const features = getOr([], 'features')(user)
  return features.includes(feature)
}
