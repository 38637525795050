import axiosInstance from '../config'
import { SUBSCRIPTION_ACTION } from './constants'

export const retrieveSubscription = () => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.get('/subscription')
  const responseData = response.data

  dispatch({
    type: SUBSCRIPTION_ACTION.UPDATE_SUBSCRIPTION,
    payload: responseData
  })
}
