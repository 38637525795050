import React, { useEffect } from 'react'
import { Crisp } from 'crisp-sdk-web'
import { connect } from 'react-redux'
import moment from 'moment'

import { CRISP_WEBSITE_ID } from '../constants/config'
import { getAuthUser } from '../data/auth/selectors'
import { getOr, isEmpty } from 'lodash/fp'
import { getCurrentSubscription } from '../data/subscription/selectors'

const SUBSCRIPTION_URL = "https://console.firebase.google.com/u/1/project/flexbot-7a255/firestore/databases/-default-/data/~2Fsubscriptions~2F"
const USER_URL = "https://console.firebase.google.com/u/1/project/flexbot-7a255/firestore/databases/-default-/data/~2Fusers~2F"

const CrispInit = (props) => {
  const { user, subscription } = props

  useEffect(() => {
    if (CRISP_WEBSITE_ID) {
      Crisp.configure(CRISP_WEBSITE_ID)
    }
  }, [CRISP_WEBSITE_ID])

  useEffect(() => {
    if (user.email) {
      Crisp.user.setEmail(user.email)
    }
    if (user.id) {
      Crisp.session.setData({
        user_id: user.id,
        user_page: `${USER_URL}${user.id}`,
        subscription_page: `${SUBSCRIPTION_URL}${user.id}`,
      })
    }
  }, [user])

  // useEffect(() => {
  //   if (!isEmpty(subscription)) {
  //     Crisp.session.setData({
  //       subscription: getOr('Free', 'name', subscription),
  //       end_date: subscription.end_date ? moment(getOr(null, 'end_date', subscription)).format('YYYY-MM-DD') : 'None',
  //     })
  //   }
  // }, [subscription])

  return null
}

const mapStateToProps = (state) => {
  return {
    user: getAuthUser(state),
    subscription: getCurrentSubscription(state),
  }
}

export default connect(mapStateToProps)(CrispInit)
