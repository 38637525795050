import React from 'react'
import { Collapse, List } from 'antd'

const InstantOfferInstruction = () => {
  const data = [
    {
      title: 'Choose your preferred stations',
      description: 'This works best for Whole Food and Retail stations.'
    },
    {
      title: '(Optional) Specify the distance to the station.',
      description: 'You can use a random number between 50 to 100 or leave it empty.'
    },
    {
      title: 'Initiate the search.'
    },
    {
      title: 'Open the Flex app on your smartphone.',
      description:
        'This is to inform Amazon of the device to which they can send the instant offer.'
    },
    {
      title: 'Once completed, close the Flex app.'
    }
  ]

  return (
    <Collapse
      style={{ marginBottom: 20 }}
      items={[
        {
          label: 'Instructions',
          children: (
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    title={`${index + 1}. ${item.title}`}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          )
        }
      ]}
    />
  )
}

export default InstantOfferInstruction
