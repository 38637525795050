import React from 'react'
import getOr from 'lodash/fp/getOr'
import moment from 'moment'

import { maybeRound } from './helpers'
import { styled } from 'styled-components'
import { COLORS } from '../../constants/styles'
import { OFFER_STATUS } from '../../constants'

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardWrapper = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${COLORS.GRAY_4};
`

const TextWarning = styled.div`
  color: ${COLORS.ORANGE_FLEX};
  font-weight: bold;
  text-align: center;
`

const OfferCardV1 = (props) => {
  const { offer, station, hideCaptchaRequired, hideReason } = props

  const startTime = moment.unix(offer.start_time)
  const endTime = moment.unix(offer.end_time)

  const duration = moment.duration(endTime.diff(startTime))
  let formattedDuration = `${Math.floor(duration.asHours())} hr`
  if (duration.minutes() > 0) {
    formattedDuration += ` ${duration.minutes()} min`
  }

  const stationName = getOr('', 'station_name')(station)


  return (
    <CardWrapper>
      <SpaceBetween>
        <div
          style={{

            fontSize: '20px',
            fontWeight: '500',
            color: COLORS.ORANGE_FLEX
          }}
        >
          ${maybeRound(offer.pay_rate)}/hour
        </div>
        <div>
          <img
            style={{ width: '18px', marginRight: '10px' }}
            src={'./assets/icons/amount.png'}
            alt="amount"
          />
          <span
            style={{
              fontSize: '18px',
              fontWeight: '500',
              color: COLORS.ORANGE_FLEX
            }}
          >
            ${offer.block_rate}
          </span>
        </div>
      </SpaceBetween>
      <SpaceBetween>
        <div style={{ fontSize: '15px' }}>{formattedDuration}</div>
        <div style={{ fontWeight: '600', fontSize: '16px', color: COLORS.BLUE }}>
          {startTime.format('ddd, MM/DD')}: {startTime.format('h:mm A')} -{' '}
          {endTime.format('h:mm A')}
        </div>
      </SpaceBetween>
      <div style={{ marginTop: '9px' }}>
        <img
          style={{ width: '14px', marginRight: '10px' }}
          src={'./assets/icons/location.png'}
          alt="Found"
        />
        <span>{stationName}</span>
      </div>
      <div style={{ marginTop: '9px' }}>
        <img
          style={{ width: '14px', marginRight: '10px' }}
          src={'./assets/icons/found.png'}
          alt="Found"
        />
        <span>{moment(offer.created_at).calendar()}</span>
      </div>
      {!hideReason && offer.skip_reason && (
        <div style={{ marginTop: '9px' }}>
          <img
            style={{ width: '14px', marginRight: '10px' }}
            src={'./assets/icons/exclaimation.png'}
            alt="Found"
          />
          <span>{offer.skip_reason}</span>
        </div>
      )}
      {!hideCaptchaRequired && offer.status === OFFER_STATUS.CAPTCHA_REQUIRED && (
        <TextWarning>CAPTCHA REQUIRED</TextWarning>
      )}
      {!hideCaptchaRequired && offer.status === OFFER_STATUS.CAPTCHA_MISSED && (
        <TextWarning>Solved Captcha - Block gone</TextWarning>
      )}
    </CardWrapper>
  )
}

export default OfferCardV1
