export const RANGE_ACTION = {
  LIST: 'range/LIST',
  CREATE: 'range/CREATE',
  REMOVE: 'range/REMOVE'
}

export const DAY_ACTION = {
  LIST: 'day/LIST',
  UPDATE: 'day/UPDATE'
}
