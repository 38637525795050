import React, { useEffect } from 'react'
import { Col, Row, Tabs } from 'antd'
import isEmpty from 'lodash/fp/isEmpty'
import { connect } from 'react-redux'

import { OFFER_STATUS } from '../../constants'
import OfferList from './offer-list'
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'
import { listStations } from '../../data/station/actions'

const DashboardOffers = (props) => {
  const { fetchStations, offers, stations } = props

  useEffect(() => {
    if (isEmpty(stations)) {
      fetchStations()
    }
  }, [])

  const acceptedOffers = []
  const missedOffers = []
  const skippedOffers = []

  offers.forEach((offer) => {
    if (offer.status === OFFER_STATUS.ACCEPTED) {
      acceptedOffers.push(offer)
    } else if (offer.status === OFFER_STATUS.MISSED || offer.status === OFFER_STATUS.CAPTCHA_REQUIRED || offer.status === OFFER_STATUS.CAPTCHA_MISSED) {
      missedOffers.push(offer)
    } else {
      skippedOffers.push(offer)
    }
  })

  const items = [
    {
      key: '1',
      label: 'Accepted',
      children: <OfferList offers={acceptedOffers} stations={stations} />
    },
    {
      key: '2',
      label: 'Missed',
      children: <OfferList offers={missedOffers} stations={stations} />
    },
    {
      key: '3',
      label: 'Skipped',
      children: <OfferList offers={skippedOffers} stations={stations} />
    }
  ]

  return (
    <div>
      <Tabs items={items} />
     
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    stations: selectors.list(state, ENTITY_TYPE.STATIONS)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStations: () => dispatch(listStations())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOffers)
