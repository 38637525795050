import React, { useState } from 'react'

import OfferCardV1 from './offer-card-v1'
import { Button, Select } from 'antd'
import { getOr, orderBy } from 'lodash/fp'
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'
import moment from 'moment'

const ORDER = {
  ASC: 'asc',
  DES: 'desc'
}

const options = [
  { value: 'created_at', label: 'Found' },
  { value: 'pay_rate', label: 'Pay Rate' },
  { value: 'block_rate', label: 'Total Pay' }
]

const OfferList = (props) => {
  const { offers, stations, hideCaptchaRequired, hideReason } = props

  const [orderTerm, setOrderTerm] = useState(options[0].value)
  const [order, setOrder] = useState(ORDER.DES)

  const sortedOffers = orderBy((offer) => {
    if (orderTerm === 'created_at') return moment(offer.created_at)
    return offer[orderTerm]
  })(order)(offers)

  const listItems = sortedOffers.map((offer) => {
    const station = stations.find((sta) => sta.station_id === offer.station_id)

    return <OfferCardV1 key={offer.pk} offer={offer} station={station} hideCaptchaRequired={hideCaptchaRequired} hideReason={hideReason} />
  })

  return (
    <div>
      <div>
        Sort by:{' '}
        <Select onChange={(value) => setOrderTerm(value)} options={options} value={orderTerm} style={{ width: 120 }} />
        <div style={{ float: 'right' }}>
          {order === ORDER.ASC && (
            <Button onClick={() => setOrder(ORDER.DES)}>
              <SortAscendingOutlined />
            </Button>
          )}
          {order === ORDER.DES && (
            <Button onClick={() => setOrder(ORDER.ASC)}>
              <SortDescendingOutlined />
            </Button>
          )}
        </div>
      </div>
      {listItems}
    </div>
  )
}

export default OfferList
