import axiosInstance from '../config'
import { AUTH_ACTION } from '../auth/constants'
import { handleError } from '../feedback/actions'

export const startSearching =
  (data = {}) =>
    async (dispatch, getState) => {
      try {
        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.put('/start', data)
        const responseData = response.data

        return dispatch({
          type: AUTH_ACTION.UPDATE_USER,
          payload: responseData
        })
      } catch (error) {
        handleError(error, dispatch)
        throw error
      }
    }

export const stopSearching =
  (data = {}) =>
    async (dispatch, getState) => {
      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const response = await axiosInstance.put('/stop', data)
      const responseData = response.data

      dispatch({
        type: AUTH_ACTION.UPDATE_USER,
        payload: responseData
      })
    }

export const startInstantOfferSearching =
  (data = {}) =>
    async (dispatch, getState) => {
      try {
        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.put('/instant_offer/start', data)
        const responseData = response.data

        return dispatch({
          type: AUTH_ACTION.UPDATE_USER,
          payload: responseData
        })
      } catch (error) {
        handleError(error, dispatch)
        throw error
      }
    }

export const stopInstantOfferSearching =
  (data = {}) =>
    async (dispatch, getState) => {
      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const response = await axiosInstance.put('/instant_offer/stop', data)
      const responseData = response.data

      dispatch({
        type: AUTH_ACTION.UPDATE_USER,
        payload: responseData
      })
    }

export const getInstantOfferLastLocation = () => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.get('/instant_offer/last_location')
  const responseData = response.data

  return responseData
}
