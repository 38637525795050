import axiosInstance from '../config'
import { handleError, setFeedback } from '../feedback/actions'

export const listRecentOffers =
  (options = {}) =>
    async (dispatch, getState) => {
      try {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: true
          }

          dispatch(setFeedback(options.feedbackKey, feedback))
        }

        const token = localStorage.getItem('token')
        axiosInstance.defaults.headers.common.Authorization = token

        const response = await axiosInstance.get('/offers/recent')
        const responseData = response.data

        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }

        return responseData
      } catch (error) {
        if (options.feedbackKey) {
          const feedback = {
            isLoading: false
          }

          dispatch(setFeedback(options.feedbackKey, feedback, true))
        }
        throw error
      }
    }
