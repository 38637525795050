import axiosInstance from '../config'
import { DAY_ACTION, RANGE_ACTION } from './constants'

export const listRanges = (day) => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.get(`/ranges/${day}`)
  const responseData = response.data

  dispatch({
    type: RANGE_ACTION.LIST,
    payload: responseData
  })
}

export const addRange = (day, data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.post(`/ranges/${day}/new`, data)
  const responseData = response.data

  dispatch({
    type: RANGE_ACTION.CREATE,
    payload: responseData
  })
}

export const removeRange = (id) => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.delete(`/ranges/${id}`)

  dispatch({
    type: RANGE_ACTION.REMOVE,
    payload: id
  })

  return response
}

export const listDays = () => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.get('/days')
  const responseData = response.data

  dispatch({
    type: DAY_ACTION.LIST,
    payload: responseData
  })
}

export const updateDay = (day, data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.put(`/day/${day}`, data)
  const responseData = response.data

  dispatch({
    type: DAY_ACTION.UPDATE,
    payload: responseData
  })
}
