import { getOr } from 'lodash/fp'

import { FEEDBACK_ACTION, FEEDBACK_TYPE } from './constants'
import { is4xxError } from './helpers'

export const clearGeneralFeedback = () => async (dispatch, getState) => {
  dispatch({
    type: FEEDBACK_ACTION.CLEAR_GENERAL
  })
}

export const setGeneralFeedback = (feedback) => async (dispatch, getState) => {
  dispatch({
    type: FEEDBACK_ACTION.SET_GENERAL,
    payload: feedback
  })

  setTimeout(() => {
    dispatch(clearGeneralFeedback())
  }, 5000)
}

export const setSuccessFeedback = (message = 'Saved') => async (dispatch, getState) => {
  dispatch(setGeneralFeedback({
    message,
    type: FEEDBACK_TYPE.SUCCESS
  }))
}

export const setFeedback = (feedbackKey, feedback, clear = false) => async (dispatch, getState) => {
  dispatch({
    type: FEEDBACK_ACTION.SET_FEEDBACK,
    payload: {
      feedbackKey,
      feedback
    }
  })

  if (clear) {
    setTimeout(() => {
      dispatch(clearFeedback(feedbackKey))
    }, 5000)
  }
}

export const clearFeedback = (feedbackKey) => async (dispatch, getState) => {
  dispatch({
    type: FEEDBACK_ACTION.CLEAR_FEEDBACK,
    payload: {
      feedbackKey
    }
  })
}

export const handleError = (error, dispatch) => {
  const status = getOr(null, 'response.status')(error)
  const message = getOr(null, 'response.data.message')(error)

  const feedback = {
    message,
    type: FEEDBACK_TYPE.ERROR
  }

  if (is4xxError(status)) {
    dispatch(setGeneralFeedback(feedback))
  }
}
