import { configureStore } from '@reduxjs/toolkit'

import { ENTITY_TYPE } from './entities/constants'
import authReducer from './auth/reducer'
import stationReducer from './station/reducer'
import { dayReducer, rangeReducer } from './day/reducer'
import offerReducer from './offer/reducer'
import feedbackReducer from './feedback/reducer'
import subscriptionReducer from './subscription/reducer'
import scheduledOfferReducer from './scheduled-offer/reducer'

export default configureStore({
  reducer: {
    [ENTITY_TYPE.OFFERS]: offerReducer,
    [ENTITY_TYPE.AUTH]: authReducer,
    [ENTITY_TYPE.STATIONS]: stationReducer,
    [ENTITY_TYPE.RANGES]: rangeReducer,
    [ENTITY_TYPE.FEEDBACK]: feedbackReducer,
    [ENTITY_TYPE.SUBSCRIPTION]: subscriptionReducer,
    [ENTITY_TYPE.DAYS]: dayReducer,
    [ENTITY_TYPE.SCHEDULED_OFFERS]: scheduledOfferReducer
  }
})
