import React from 'react'
import { styled } from 'styled-components'
import { Button } from 'antd'

import TimePickerWithoutSeconds from '../../ui/time-picker'
import { DeleteOutlined } from '@ant-design/icons'

const SpaceBetween = styled.div`p
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledDiv = styled.div`
  display: flex;
  padding: 10px 0;
  gap: 10px;
  align-items: center;
`

const StyledButton = styled(Button)`
  margin-left: 10px;
  margin-top: 20px;
`

const BotScheduleItem = (props) => {
  const { schedule, handleRemove, index, handleUpdate } = props
  const { start_time, end_time } = schedule

  const handleChange = (key, value) => {
    handleUpdate(index, { ...schedule, [key]: value });
  };

  return (
    <SpaceBetween>
      <StyledDiv>
        <div>
          <p>Start Time</p>
          <TimePickerWithoutSeconds
            onChange={(value) => handleChange('start_time', value)}
            value={start_time}
          />
        </div>
        <div>
          <p>End Time</p>
          <TimePickerWithoutSeconds
            onChange={(value) => handleChange('end_time', value)}
            value={end_time}
          />
        </div>
        <div>
          <StyledButton
            type="primary"
            onClick={() => handleRemove(index)}
            danger
          >
            <DeleteOutlined />
          </StyledButton>
        </div>
      </StyledDiv>
    </SpaceBetween>
  )
}

export default BotScheduleItem
