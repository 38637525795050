import React, { useEffect, useState } from 'react'
import { Space, Spin, Row, Col, Typography } from 'antd'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import moment from 'moment'
import { connect } from 'react-redux'

import { getAuthUser } from '../../data/auth/selectors'
import { startSearching, stopSearching } from '../../data/search/actions'
import DashboardOnboard from './dashboard-onboard'
import { refresh, submitAmzUrl } from '../../data/auth/actions'
import { listOffers } from '../../data/offer/actions'
import DashboardOffers from './dashboard-offers'
import { currentSessionOffers } from '../../data/offer/selectors'
import withSubscription from '../subscription/with-subscription'
import { getFeedback } from '../../data/feedback/selectors'
import { SEARCH_PATTERN } from '../../constants'
import { LoadingOutlined } from '@ant-design/icons'
import { LargeButton } from '../../ui/button'
import { useMediaQuery } from 'react-responsive'
import { COLORS } from '../../constants/styles'

export const FB_KEY_SUBMIT_AMZ_URL = 'fbSubmitAmzUrl'

const { Text } = Typography

const DashboardContainer = (props) => {
  const { feedback, fetchOffers, offers, start, stop, submitUrl, user, refresh } = props

  const [loading, setLoading] = useState(true)

  const useAltLayout = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token')
    if (refreshToken) {
      refresh({ refresh_token: refreshToken }).then(() => setLoading(false))
    }
    fetchOffers()
  }, [])

  const isRunning = getOr(false, 'is_running')(user)
  const amzVerify = getOr(false, 'amz_verify')(user)
  const botScheduleEnabled = getOr(false, 'bot_schedule_enabled')(user)

  if (isEmpty(user) || loading) {
    return (
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 40
            }}
            spin
          />
        }
      />
    )
  }

  if (!amzVerify)
    return (
      <DashboardOnboard feedback={feedback} user={user} submitUrl={submitUrl} />
    )

  let spinTip = useAltLayout ? null : 'Searching...'

  const searchPattern = getOr(SEARCH_PATTERN.STANDARD, 'search_pattern')(user)

  const sleepingStartTimestamp = getOr(null, 'sleeping_start_timestamp')(user)
  const sleepingTime = getOr(null, 'sleeping_time')(user)
  const sleepingStartMoment = moment.unix(sleepingStartTimestamp)

  const totalSearchTime = getOr(0, 'total_search_time')(user)
  const searchStartAt = getOr(null, 'search_start_at')(user)

  if (sleepingStartTimestamp) {
    let sleepingEndMoment = sleepingStartMoment
      .clone()
      .add(sleepingTime, 'minutes')
    const currentMoment = moment()

    // STANDARD
    let isWithinInterval = currentMoment.isBetween(
      sleepingStartMoment,
      sleepingEndMoment
    )

    if (searchPattern === SEARCH_PATTERN.ADVANCED) {
      const searchStartAtMoment = moment.unix(searchStartAt)

      sleepingEndMoment = searchStartAtMoment
        .clone()
        .add(totalSearchTime, 'minutes')
      isWithinInterval = sleepingStartMoment.isBetween(
        searchStartAtMoment,
        sleepingEndMoment
      )
    } else if (searchPattern === SEARCH_PATTERN.FIXED_INTERVAL) {
      const searchStartAtMoment = moment.unix(searchStartAt)
      sleepingEndMoment = searchStartAtMoment.clone().add(5, 'minutes')
      isWithinInterval = currentMoment.isBetween(
        sleepingStartMoment,
        sleepingEndMoment
      )
    }

    if (isWithinInterval) {
      const remainingTimeMinutes =
        sleepingEndMoment.diff(currentMoment, 'minutes') + 1
      spinTip = `${
        useAltLayout ? '' : 'Sleeping. '
      }Your search will resume in ${remainingTimeMinutes} minute(s)`
    }
  }

  return (
    <div>
      <Row>
        <Col xs={24} md={24} lg={16}>
          {isRunning && (
            <Space direction="vertical">
              {useAltLayout ? (
                <span style={{ color: COLORS.ORANGE_FLEX }}>{spinTip}</span>
              ) : (
                <Spin tip={spinTip} size="large">
                  <div className="content" />
                </Spin>
              )}
              <LargeButton
                onClick={stop}
                type="primary"
                style={{
                  marginTop: useAltLayout ? 0 : '2rem',
                  padding: '0 50px'
                }}
                disabled={botScheduleEnabled}
              >
                Stop Searching
              </LargeButton>
            </Space>
          )}
          {!isRunning && (
            <Space direction="vertical">
              <LargeButton
                style={{ padding: '0 50px', marginBottom: '30px' }}
                onClick={start}
                type="primary"
                disabled={botScheduleEnabled}
              >
                Start Searching
              </LargeButton>
            </Space>
          )}
          {botScheduleEnabled && (
            <Space direction="vertical">
              <Text type="warning">
                Start and Stop are controlled by Bot Schedule
              </Text>
            </Space>
          )}

          {/* <Divider /> */}

          <DashboardOffers offers={offers} />
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: getAuthUser(state),
    offers: currentSessionOffers(state),
    feedback: getFeedback(state, FB_KEY_SUBMIT_AMZ_URL)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOffers: () => dispatch(listOffers()),
    start: () => dispatch(startSearching()),
    stop: () => dispatch(stopSearching()),
    submitUrl: (data) =>
      dispatch(submitAmzUrl(data, { feedbackKey: FB_KEY_SUBMIT_AMZ_URL })),
    refresh: (data) => dispatch(refresh(data))
  }
}

export default withSubscription(
  connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
)
