import React, { useEffect, useState } from 'react'
import { Card, Col, Divider, Popconfirm, Row } from 'antd'
import omit from 'lodash/fp/omit'
import moment from 'moment'
import { connect } from 'react-redux'
import { styled } from 'styled-components'
import { defaultTo, mapValues } from 'lodash/fp'

import PaymentMethodList from './payment-method-list'
import PlanManagementDialog from './plan-managent-dialog'
import PaymentMethodDialog from './payment-method-dialog'
import {
  cancelPlan,
  createPaymentMethod,
  deletePaymentMethod,
  getDefaultPaymentMethod,
  listPaymentMethods,
  resumePlan,
  setDefaultPaymentMethod,
  validatePlan
} from '../../data/billing/actions'
import { getCurrentSubscription } from '../../data/subscription/selectors'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { COLORS } from '../../constants/styles'
import { SUBSCRIPTION_STATUS } from '../../constants'
import { getAuthUser } from '../../data/auth/selectors'

import SubscriptionStatusLabel from './subscription-status-label'
import { formattedCurrency } from './helpers'

const StyledCard = styled(Card)`
  margin-top: 15px;

  .ant-card-head-title {
    font-weight: bold;
  }
`

const StyledNextInvoice = styled.div`
  margin-top: 10px;
  color: ${COLORS.GRAY_2};
  .anticon {
    margin-right: 10px;
  }
`

const SubscriptionContainer = (props) => {
  const {
    currentSubscription,
    getDefaultPaymentMethod,
    listPaymentMethods,
    onCreatePaymentMethod,
    onDeletePaymentMethod,
    setDefaultPaymentMethod,
    user,
    validatePlan,
    onCancel,
    onResume
  } = props

  const [paymentMethods, setPaymentMethods] = useState({})

  useEffect(() => {
    listPaymentMethods().then((pms) => {
      getDefaultPaymentMethod().then((pm) => {
        if (pm && pm.id) {
          pms[pm.id].default = true
        }
        setPaymentMethods(pms)
      })
    })
  }, [])

  const handleCreatePaymentMethod = (paymentMethodId) =>
    onCreatePaymentMethod({ payment_method_id: paymentMethodId }).then((pm) =>
      setPaymentMethods({ ...paymentMethods, ...pm })
    )

  const handleDeletePaymentMethod = (id) =>
    onDeletePaymentMethod(id).then(() => setPaymentMethods(omit([id], paymentMethods)))

  const handleSetDefaultPaymentMethod = (id) =>
    setDefaultPaymentMethod(id).then(() => {
      const newPaymentMethods = mapValues((pm) => omit(['default'])(pm))(paymentMethods)
      newPaymentMethods[id].default = true
      setPaymentMethods(newPaymentMethods)
    })

  const isFuture = moment(currentSubscription.end_date) > moment()

  const showCancel =
    !currentSubscription?.cancel_at_period_end && currentSubscription?.status === SUBSCRIPTION_STATUS.ACTIVE

  return (
    <Row>
      <Col xs={24} md={12}>
        <StyledCard
          title={
            <span>
              Subscription <SubscriptionStatusLabel subscription={currentSubscription} />
            </span>
          }
        >
          <p>
            Your current plan:{' '}
            <strong style={{ fontSize: '1.2rem' }}>{defaultTo('Free Trial')(currentSubscription.name)}</strong>
          </p>
          {!currentSubscription.name && currentSubscription.end_date && (
            <StyledNextInvoice>
              <ExclamationCircleOutlined />
              Your Free Trial {isFuture ? 'will expire' : 'expired'} on{' '}
              {moment(currentSubscription.end_date).format('MMM Do YYYY')}
            </StyledNextInvoice>
          )}
          {currentSubscription.name &&
            currentSubscription.end_date &&
            currentSubscription.status === SUBSCRIPTION_STATUS.ACTIVE && (
              <>
                <StyledNextInvoice>
                  <ExclamationCircleOutlined />
                  {currentSubscription?.cancel_at_period_end
                    ? 'Your subscription will be cancelled on'
                    : `${formattedCurrency(currentSubscription.amount)} will be billed on`}{' '}
                  {moment(currentSubscription.end_date).format('MMMM Do YYYY')}
                </StyledNextInvoice>
              </>
          )}
          <Divider />
          {currentSubscription?.cancel_at_period_end &&
            currentSubscription?.status !== SUBSCRIPTION_STATUS.CANCELLED && (
              <a onClick={onResume} style={{ marginRight: '10px' }}>
                Resume subscription
              </a>
          )}
          <PlanManagementDialog validatePlan={validatePlan} />
        </StyledCard>
      </Col>

      <Col xs={0} md={1} />

      {user.stripe_customer_id && (
        <Col xs={24} md={12}>
          <StyledCard title='Billing Information'>
            <PaymentMethodList
              subscription={currentSubscription}
              paymentMethods={paymentMethods}
              onSetDefault={handleSetDefaultPaymentMethod}
              onDelete={handleDeletePaymentMethod}
            />

            <Divider />
            <PaymentMethodDialog onConfirm={handleCreatePaymentMethod} />
          </StyledCard>
        </Col>
      )}

      <Divider />
      {showCancel && (
        <div>
          Need to cancel your subscription?{' '}
          <Popconfirm
            cancelText='No'
            okText='Yes'
            title='Are you sure want to cancel the subscription?'
            onConfirm={onCancel}
          >
            <a>Cancel</a>
          </Popconfirm>
        </div>
      )}
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    currentSubscription: getCurrentSubscription(state),
    user: getAuthUser(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listPaymentMethods: () => dispatch(listPaymentMethods()),
    getDefaultPaymentMethod: () => dispatch(getDefaultPaymentMethod()),
    setDefaultPaymentMethod: (id) => dispatch(setDefaultPaymentMethod(id)),
    onCreatePaymentMethod: (data) => dispatch(createPaymentMethod(data)),
    onDeletePaymentMethod: (id) => dispatch(deletePaymentMethod(id)),
    validatePlan: (planId) => dispatch(validatePlan(planId)),
    onCancel: () => dispatch(cancelPlan()),
    onResume: () => dispatch(resumePlan())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionContainer)
