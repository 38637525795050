import React, { useState } from 'react'
import { Button, Image, Input, Space } from 'antd'

import { AMZ_LOGIN_URL } from '../../constants'
import { LargeButton } from '../../ui/button'

const DashboardOnboard = (props) => {
  const { feedback, submitUrl } = props
  const [form, setForm] = useState({
    amz_url: ''
  })

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value })

  const handleSubmit = () => submitUrl(form).catch(() => setForm({ ...form, amz_url: '' }))

  const { amz_url } = form

  return (
    <div>
      <LargeButton style={{ padding: '0 50px' }} type='primary'>
        <a href={AMZ_LOGIN_URL} target='_blank' rel='noreferrer'>
          Login with Amazon Flex account
        </a>
      </LargeButton>

      <p style={{ margin: '15px 0 10px' }}>Copy the resulting URL to this input and Submit</p>
      <Image src='onboarding.png' />
      <Space>
        <Input name='amz_url' onChange={handleChange} value={amz_url} />
        <Button loading={feedback.isLoading} onClick={handleSubmit} type='primary'>
          Submit
        </Button>
      </Space>
    </div>
  )
}

export default DashboardOnboard
