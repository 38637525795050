import React, { useState } from "react";
import { Button, Checkbox, InputNumber, Modal } from "antd";
import capitalize from "lodash/fp/capitalize";

import TimePickerWithoutSeconds from "../../ui/time-picker";
import { styled } from "styled-components";
import { COLORS } from "../../constants/styles";
import Message from '../../ui/message';
import { isEmpty } from 'lodash/fp';

const StyledText = styled.p`
  color: ${COLORS.GRAY_2};
  font-style: italic;
`;

const StyledInputNumber = styled(InputNumber)`
  padding: 7px 10px;
`;

const DayRangeForm = (props) => {
  const { day, open, onClose, onSubmit } = props;

  const [form, setForm] = useState({
    start_time: "00:00",
    end_time: "23:59",
    min_rate: null,
    // stations: [],
  });

  const handleChange = (key, value) => setForm({ ...form, [key]: value })

  const handleSubmit = () => {
    onClose();
    return onSubmit(form);
  };

  const { start_time, end_time, min_rate } = form;

  // const handleSelectStation = (station) => {
  //   const stationIndex = stations.indexOf(station.station_id);
  //   if (stationIndex > -1) {
  //     setForm({
  //       ...form,
  //       stations: stations.filter((_, index) => index !== stationIndex),
  //     });
  //   } else {
  //     setForm({
  //       ...form,
  //       stations: [...stations, station.station_id],
  //     });
  //   }
  // };

  // const stationListItems = activeStations.map((station) => (
  //   <Checkbox onChange={() => handleSelectStation(station)}>{station.station_name}</Checkbox>
  // ));

  return (
    <Modal
      title={`Add new Range for ${capitalize(day)}`}
      open={open}
      onOk={onSubmit}
      onCancel={onClose}
      footer={[
        <Button key='back' onClick={onClose}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' onClick={handleSubmit}>
          Confirm
        </Button>
      ]}
    >
      <div style={{ display: "flex", marginBottom: "15px", gap: "10px" }}>
        <div>
          <p>Start Time</p>
          <TimePickerWithoutSeconds onChange={(value) => handleChange('start_time', value)} value={start_time} />
        </div>
        <div>
          <p>End Time</p>
          <TimePickerWithoutSeconds onChange={(value) => handleChange('end_time', value)} value={end_time} />
        </div>
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <p>Min Rate ($)</p>
        <StyledInputNumber
          onChange={(value) => handleChange('min_rate', value)}
          prefix='$'
          suffix='/ hr'
          style={{ width: '150px' }}
          value={min_rate}
        />
        <StyledText>This will override the Min Rate of the stations.</StyledText>
      </div>
      {/* <div>{stationListItems}</div>
      {!isEmpty(form.stations) &&
        <div style={{ marginTop: '10px' }}>
          <Message content="This range will be only applied to selected stations" warning />
        </div>
      } */}
    </Modal>
  )
}

export default DayRangeForm
