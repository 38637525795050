import React, { useEffect, useState } from 'react'
import { Button, Modal, Radio, Space, Tooltip, Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const SELFIE_OPTION = {
  DEFAULT: 'default',
  UPLOAD_NEW: 'upload'
}

const SelfieModal = (props) => {
  const { isLoading, canBypassSelfie, uploadSelfiePhoto, scheduledOffer, onBypassSelfie } = props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selfieSelection, setSelfieSelection] = useState(SELFIE_OPTION.DEFAULT)
  const [canSubmit, setCanSubmit] = useState(true)

  useEffect(() => {
    if (selfieSelection === SELFIE_OPTION.UPLOAD_NEW) {
      setCanSubmit(false)
    } else if (selfieSelection === SELFIE_OPTION.DEFAULT) {
      setCanSubmit(true)
    }
  }, [selfieSelection])

  const handleOpen = () => setIsModalOpen(true)
  const handleClose = () => setIsModalOpen(false)

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }

    return isJpgOrPng
  }

  const handleUpload = (uploadData) => {
    const formData = new FormData()
    formData.append('selfie', uploadData.file, uploadData.filename)

    uploadSelfiePhoto(formData)
      .then(() => {
        setCanSubmit(true)
        uploadData.onSuccess()
      })
      .catch(() => setCanSubmit(false))
  }

  const handleChange = (e) => setSelfieSelection(e.target.value)

  const handleBypassSelfie = () => onBypassSelfie({ offer: scheduledOffer, kind: selfieSelection })

  return (
    <>
      <Tooltip placement='topRight' title='Selfie bypass is available 15 minutes before the starting time.'>
        <Button
          disabled={!canBypassSelfie}
          loading={isLoading}
          type='primary'
          onClick={handleOpen}
        >
          Bypass Selfie
        </Button>
      </Tooltip>
      <Modal
        title='Bypass Selfie'
        open={isModalOpen}
        okText='Confirm'
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button key='back' onClick={handleClose}>
            Cancel
          </Button>,
          <Button key='submit' disabled={!canSubmit} type='primary' loading={isLoading} onClick={handleBypassSelfie}>
            Submit
          </Button>
        ]}
      >
        <Radio.Group onChange={handleChange} value={selfieSelection}>
          <Space direction='vertical'>
            <Radio value={SELFIE_OPTION.DEFAULT}>Use default profile photo</Radio>
            <Radio value={SELFIE_OPTION.UPLOAD_NEW}>Upload new selfie photo</Radio>
          </Space>
        </Radio.Group>
        {selfieSelection === SELFIE_OPTION.UPLOAD_NEW && (
          <div style={{ marginTop: '15px' }}>
            <Upload
              previewFile={(file) => console.log(file)}
              customRequest={handleUpload}
              maxCount={1}
              beforeUpload={beforeUpload}
              onRemove={() => setCanSubmit(false)}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </div>
        )}
      </Modal>
    </>
  )
}

export default SelfieModal
