import axiosInstance from '../config'
import { handleError, setFeedback } from '../feedback/actions'
import { SUBSCRIPTION_ACTION } from '../subscription/constants'

export const listPlans =
  (data = {}) =>
    async (dispatch, getState) => {
      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const response = await axiosInstance.get('/plans')
      const responseData = response.data

      return responseData
    }

export const listPaymentMethods =
  (data = {}) =>
    async (dispatch, getState) => {
      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const response = await axiosInstance.get('/payment_methods')
      const responseData = response.data

      return responseData
    }

export const getDefaultPaymentMethod =
  (data = {}) =>
    async (dispatch, getState) => {
      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const response = await axiosInstance.get('/payment_methods/get_default')
      const responseData = response.data

      return responseData
    }

export const setDefaultPaymentMethod = (id) => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.put(`/payment_methods/${id}/default`)
  const responseData = response.data

  return responseData
}

export const createPaymentMethod =
  (data = {}) =>
    async (dispatch, getState) => {
      const token = localStorage.getItem('token')
      axiosInstance.defaults.headers.common.Authorization = token

      const response = await axiosInstance.post('/payment_methods', data)
      const responseData = response.data

      return responseData
    }

export const deletePaymentMethod = (id) => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.delete(`/payment_methods/${id}`)
  const responseData = response.data

  return responseData
}

export const validatePlan = (id) => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.get(`/plans/${id}/validate`)
  const responseData = response.data

  return responseData
}

export const updatePlan = (data, options = {}) => async (dispatch, getState) => {
  try {
    if (options.feedbackKey) {
      const feedback = {
        isLoading: true
      }

      dispatch(setFeedback(options.feedbackKey, feedback))
    }

    const token = localStorage.getItem('token')
    axiosInstance.defaults.headers.common.Authorization = token

    const response = await axiosInstance.post('/plans/update', data)
    const responseData = response.data

    if (options.feedbackKey) {
      const feedback = {
        isLoading: false
      }

      dispatch(setFeedback(options.feedbackKey, feedback, true))
    }

    return responseData
  } catch (error) {
    handleError(error, dispatch)

    if (options.feedbackKey) {
      const feedback = {
        isLoading: false
      }

      dispatch(setFeedback(options.feedbackKey, feedback, true))
    }
    throw error
  }
}

export const cancelPlan = (data = {}, options = {}) => async (dispatch, getState) => {
  try {
    if (options.feedbackKey) {
      const feedback = {
        isLoading: true
      }

      dispatch(setFeedback(options.feedbackKey, feedback))
    }

    const token = localStorage.getItem('token')
    axiosInstance.defaults.headers.common.Authorization = token

    const response = await axiosInstance.put('/plans/cancel', data)
    const responseData = response.data

    if (options.feedbackKey) {
      const feedback = {
        isLoading: false
      }

      dispatch(setFeedback(options.feedbackKey, feedback, true))
    }

    dispatch({
      type: SUBSCRIPTION_ACTION.UPDATE_SUBSCRIPTION,
      payload: responseData
    })

    return responseData
  } catch (error) {
    handleError(error, dispatch)

    if (options.feedbackKey) {
      const feedback = {
        isLoading: false
      }

      dispatch(setFeedback(options.feedbackKey, feedback, true))
    }
    throw error
  }
}

export const resumePlan = (data = {}, options = {}) => async (dispatch, getState) => {
  try {
    if (options.feedbackKey) {
      const feedback = {
        isLoading: true
      }

      dispatch(setFeedback(options.feedbackKey, feedback))
    }

    const token = localStorage.getItem('token')
    axiosInstance.defaults.headers.common.Authorization = token

    const response = await axiosInstance.put('/plans/resume', data)
    const responseData = response.data

    if (options.feedbackKey) {
      const feedback = {
        isLoading: false
      }

      dispatch(setFeedback(options.feedbackKey, feedback, true))
    }

    dispatch({
      type: SUBSCRIPTION_ACTION.UPDATE_SUBSCRIPTION,
      payload: responseData
    })

    return responseData
  } catch (error) {
    handleError(error, dispatch)

    if (options.feedbackKey) {
      const feedback = {
        isLoading: false
      }

      dispatch(setFeedback(options.feedbackKey, feedback, true))
    }
    throw error
  }
}
