import React, { useEffect, useState } from 'react'
import { Col, Row, Select } from 'antd'
import first from 'lodash/fp/first'
import getOr from 'lodash/fp/getOr'
import orderBy from 'lodash/fp/orderBy'
import moment from 'moment'
import { connect } from 'react-redux'

import {
  getRunningSessionOffers,
  getRunningSessions
} from '../../data/logs/actions'
import OfferLogList from './offer-log-list'
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'
import { listStations } from '../../data/station/actions'
import { getFeedback } from '../../data/feedback/selectors'
import { isEmpty } from 'lodash/fp'
import Message from '../../ui/message'

const FB_LIST_RUNNING_SESSIONS = 'fbListRunningSessions'
const FB_RUNNING_SESSION_OFFERS = 'fbRunningSessionOffers'

const LogsContainer = (props) => {
  const {
    fetchStations,
    listRunningSessions,
    listRunningSessionOffers,
    stations,
    offersFeedback,
    rsFeedback
  } = props

  const [runningSessions, setRunningSessions] = useState([])
  const [selectedSession, setSelectedSession] = useState(null)

  useEffect(() => {
    fetchStations()
    listRunningSessions().then((rs) => {
      const sortedRs = orderBy(['running_session'])('desc')(Object.values(rs))
      setRunningSessions(sortedRs)

      if (!isEmpty(sortedRs)) {
        setSelectedSession(first(sortedRs).id)
      }
    })
  }, [])

  const handleChangeRunningSession = (value) => setSelectedSession(value)

  const runningSessionOptions = runningSessions.map((rs) => ({
    label: moment.unix(rs.running_session).calendar(),
    value: rs.id
  }))

  const isLoading = getOr(false, 'isLoading')(rsFeedback)

  return (
    <Row>
      <Col xs={24} md={14}>
        <h2>Event Logs</h2>
        <div style={{ marginTop: '20px' }}>
          {!isLoading && isEmpty(runningSessions) && (
            <Message title='No logs found' />
          )}
          {!isEmpty(runningSessions) && (
            <Select
              options={runningSessionOptions}
              value={selectedSession}
              onChange={handleChangeRunningSession}
              style={{ width: '100%', marginRight: '15px' }}
            />
          )}
          <div style={{ marginTop: '20px' }}>
            <OfferLogList
              listRunningSessionOffers={listRunningSessionOffers}
              runningSession={selectedSession}
              stations={stations}
              feedback={offersFeedback}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    rsFeedback: getFeedback(state, FB_LIST_RUNNING_SESSIONS),
    offersFeedback: getFeedback(state, FB_RUNNING_SESSION_OFFERS),
    stations: selectors.list(state, ENTITY_TYPE.STATIONS)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStations: () => dispatch(listStations()),
    listRunningSessions: () =>
      dispatch(getRunningSessions({ feedbackKey: FB_LIST_RUNNING_SESSIONS })),
    listRunningSessionOffers: (runningSessionId) =>
      dispatch(
        getRunningSessionOffers(runningSessionId, {
          feedbackKey: FB_RUNNING_SESSION_OFFERS
        })
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogsContainer)
