import React from 'react'
import styled from 'styled-components'

import { COLORS, media } from '../constants/styles'

const BoxWrap = styled.div`
  min-height: 100vh;
  background-color: ${COLORS.ORANGE_FLEX};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  ${media.smallOnly`
      padding: 0;
      background-color: ${COLORS.WHITE};
    `}
`

const BoxContent = styled.div`
  width: min(400px, 90%);
  background-color: ${COLORS.WHITE};
  border-radius: 10px;
  padding: 60px 30px;

  ${media.smallOnly`
      width: 100%;
      border-radius: 0;
      height: calc(100vh - 120px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}
`

const TallBox = ({ children }) => {
  return (
    <BoxWrap>
      <BoxContent>{children}</BoxContent>
    </BoxWrap>
  )
}

export default TallBox
