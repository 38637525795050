import { Tabs } from 'antd'
import { getOr } from 'lodash/fp'
import React from 'react'
import SettingStations from './setting-stations'
import SettingDays from './setting-days'
import SettingSearch from './setting-search'
import SettingRegion from './setting-region'

const SettingsContainer = () => {
  const tabItems = [
    {
      label: 'Stations',
      key: 'station',
      children: <SettingStations />
    },
    {
      label: 'Days',
      key: 'days',
      children: <SettingDays />
    },
    {
      label: 'Search',
      key: 'search',
      children: <SettingSearch />
    },
    {
      label: 'Region',
      key: 'region',
      children: <SettingRegion />
    }
  ]

  return (
    <div>
      <Tabs defaultActiveKey={getOr(null, '[0].key')(tabItems)} items={tabItems} />
    </div>
  )
}

export default SettingsContainer
