import React from 'react'
import { LeftOutlined, LockOutlined, MailOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { PUBLIC_ASSET_URLS, URLS } from '../../constants'
import { signup } from '../../data/auth/actions'
import { LargeButton, LinkButton } from '../../ui/button'
import TallBox from '../../ui/tall-box'
import { SpanSubtle } from '../../ui/text'
import { StyledHeader } from './auth-container'
import { StyledInput, StyledInputPassword } from './auth-login'
import withAuthMonitor from './with-auth-monitor'

const AuthSignUp = (props) => {
  const { signUp } = props

  const [form] = Form.useForm()

  const navigate = useNavigate()

  const handleSignUp = (values) => signUp(values).then(() => navigate(URLS.DASHBOARD))

  return (
    <TallBox>
      <div>
        <LinkButton to={URLS.AUTH_START} style={{ width: '50px' }}>
          <LeftOutlined />
        </LinkButton>
        <img
          src={PUBLIC_ASSET_URLS.AUTH_PEOPLE}
          alt="login"
          style={{ width: '100%', margin: '0 auto', display: 'block' }}
        />
        <StyledHeader>Create your Account</StyledHeader>

        <SpanSubtle style={{ display: 'block', textAlign: 'center' }}>
          Free Instant Offers Feature
        </SpanSubtle>

        <Form form={form} onFinish={handleSignUp} style={{ marginTop: '20px' }}>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input your email!'
              }
            ]}
          >
            <StyledInput placeholder="Email" prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <StyledInputPassword
              placeholder="Password"
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <LargeButton
            block
            type="primary"
            htmlType="submit"
            style={{ marginTop: '20px' }}
          >
            Submit
          </LargeButton>
        </Form>
      </div>
    </TallBox>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (data) => dispatch(signup(data))
  }
}

export default connect(null, mapDispatchToProps)(withAuthMonitor(AuthSignUp))
