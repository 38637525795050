import React from 'react'
import isFunction from 'lodash/fp/isFunction'

/**
 * Reusable component to poll an API endpoint. Will call onPoll
 * using exponential backoff
 */
class Poller extends React.Component {
  state = {
    tries: 0,
    timeoutID: null
  }

  componentDidMount () {
    this.poll()
  }

  componentDidUpdate (prevProps) {
    const { isFinished } = this.props
    const { isFinished: prevIsFinish } = prevProps
    const { timeoutID } = this.state

    if (!isFinished && prevIsFinish !== isFinished && !timeoutID) {
      this.poll()
    }
  }

  componentWillUnmount () {
    const { timeoutID } = this.state

    if (timeoutID) {
      clearTimeout(timeoutID)
    }
  }

  poll = () => {
    const {
      baseTimeoutMs,
      isFinished,
      onHalt,
      onPoll,
      maxRetries
    } = this.props
    const { timeoutID, tries } = this.state

    if (isFinished && (!isFunction(isFinished) || isFinished())) {
      if (timeoutID) {
        clearTimeout(timeoutID)
        this.setState({ timeoutID: null })
      }
    } else {
      this.setState({ tries: tries + 1 })

      onPoll()

      if (!maxRetries || tries < maxRetries) {
        const nextTimeoutID = setTimeout(this.poll, baseTimeoutMs)
        this.setState({
          timeoutID: nextTimeoutID
        })
      } else if (onHalt) {
        onHalt()
      }
    }
  }

  render () {
    const { children = null } = this.props
    return children
  }
}

Poller.defaultProps = {
  baseTimeoutMs: 500,
  exponentialBase: 2,
  jitter: true,
  maxRetries: 6,
  maxTimeoutMs: 30 * 1000
}

export default Poller
