import { COLORS } from '../constants/styles'

const primaryTheme = {
  token: {
    colorPrimary: COLORS.ORANGE_FLEX,
    fontSize: 16,
  },
  components: {
    Button: {
      defaultBorderColor: COLORS.ORANGE_FLEX,
      defaultColor: COLORS.ORANGE_FLEX,
    },
  }
}

export default primaryTheme
