import axiosInstance from '../config'
import { STATION_ACTION } from './constants'
import { handleError, setFeedback } from '../feedback/actions'

export const listStations = () => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.get('/stations')
  const responseData = response.data

  dispatch({
    type: STATION_ACTION.LIST,
    payload: responseData
  })
}

export const refreshStations = (options = {}) => async (dispatch, getState) => {
  try {
    if (options.feedbackKey) {
      const feedback = {
        isLoading: true
      }

      dispatch(setFeedback(options.feedbackKey, feedback))
    }

    const token = localStorage.getItem('token')
    axiosInstance.defaults.headers.common.Authorization = token

    const response = await axiosInstance.get('/stations/refresh')
    const responseData = response.data

    const promise = dispatch({
      type: STATION_ACTION.LIST,
      payload: responseData,
      forceRefresh: options.forceRefresh
    })

    if (options.feedbackKey) {
      const feedback = {
        isLoading: false
      }

      dispatch(setFeedback(options.feedbackKey, feedback, true))
    }

    return promise
  } catch (error) {
    handleError(error, dispatch)

    if (options.feedbackKey) {
      const feedback = {
        isLoading: false
      }

      dispatch(setFeedback(options.feedbackKey, feedback, true))
    }
    throw error
  }
}

export const updateStation = (stationId, data) => async (dispatch, getState) => {
  const token = localStorage.getItem('token')
  axiosInstance.defaults.headers.common.Authorization = token

  const response = await axiosInstance.put(`/stations/${stationId}`, data)
  const responseData = response.data

  dispatch({
    type: STATION_ACTION.UPDATE,
    payload: responseData
  })
}
