import { css } from 'styled-components'

export const COLORS = {
  ORANGE_FLEX: '#FF9900',
  ORANGE_LIGHT: '#fff1d9',

  YELLOW_WARNING: '#c9ba9b',
  YELLOW_WARNING_BG: 'rgb(255, 250, 243)',

  BROWN_WARNING_TITLE: 'rgb(121, 75, 2)',
  BROWN_WARNING_TEXT: 'rgb(87, 58, 8)',

  BLACK: '#000000',

  GREEN: '#0a8852',

  WHITE: '#fff',

  RED: 'red',

  GRAY: 'rgb(240, 240, 240)',
  GRAY_2: 'rgba(0, 0, 0, 0.4)',
  GRAY_3: '#ADB3C5',
  GRAY_4: '#D2D6E0',

  BLUE: '#22357A'
}

export const SCREEN_XSMALL_W = 480
export const SCREEN_XSMALL_H = 480
export const SCREEN_SMALL_W = 640
export const SCREEN_TABLET_MIN_W = 768
export const SCREEN_TABLET_MAX_W = 1024
export const SCREEN_MEDIUM_W = 1024
export const SCREEN_LARGE_W = 1280
export const SCREEN_XLARGE_W = 1920
export const CONTAINER_SIGNING_LARGE_W = 1100 // max signing width
export const CUSTOM_MESSAGES_MIN_WIDTH = 1700

// z-index of 1002 fixes an issue where
export const Z_INDEX_SIDEBAR = 1002
export const Z_INDEX_HEADER = 1002

export const mediaSizes = {
  tinyOnly: [0, SCREEN_XSMALL_W],
  smallOnly: [0, SCREEN_SMALL_W],
  // see smallOnlyWidthHeight below as well
  smallUp: SCREEN_XSMALL_W + 1,
  mediumUp: SCREEN_SMALL_W + 1,
  // 768 is a default breakpoint for some semantic ui react components, so mediumAlt is sometimes necessary
  // to be in sync with semantic ui react
  mediumAltUp: 769,
  largeUp: SCREEN_MEDIUM_W + 1,
  xlargeUp: SCREEN_LARGE_W + 1,
  xxlargeUp: SCREEN_XLARGE_W + 1
}

export const media = {}

Object.keys(mediaSizes).forEach((label) => {
  const size = mediaSizes[label]

  if (Array.isArray(size) && size.length === 2) {
    media[label] = (...args) => css`
            @media (min-width: ${size[0]}px)  and (max-width: ${size[1]}px) {
                ${css(...args)}
            }
        `
  } else {
    media[label] = (...args) => css`
            @media (min-width: ${size}px) {
                ${css(...args)}
            }
        `
  }
})

media.smallOnlyWidthHeight = (...args) => css`
    @media (max-width: ${SCREEN_SMALL_W}px), (max-height: ${SCREEN_XSMALL_H}px) {
        ${css(...args)}
    }
`
