import React from 'react'
import { Tag } from 'antd'
import { SUBSCRIPTION_STATUS } from '../../constants'

const SubscriptionStatusLabel = ({ subscription }) => {
  if (!subscription) return null

  const { status } = subscription
  let statusColor = ''
  let statusDisplay = ''

  if (status === SUBSCRIPTION_STATUS.ACTIVE) {
    if (subscription.cancel_at_period_end) {
      statusColor = 'yellow'
      statusDisplay = 'Scheduled Cancel'
    } else {
      statusColor = 'green'
      statusDisplay = 'Active'
    }
  } else if (status === SUBSCRIPTION_STATUS.CANCELLED) {
    statusColor = 'red'
    statusDisplay = 'Cancelled'
  } else if (status === SUBSCRIPTION_STATUS.PAST_DUE) {
    statusColor = 'yellow'
    statusDisplay = 'Past Due'
  }

  return <Tag color={statusColor}>{statusDisplay}</Tag>
}

export default SubscriptionStatusLabel
