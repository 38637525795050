import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { refresh } from '../../data/auth/actions'
import { retrieveSubscription } from '../../data/subscription/actions'

/**
 * This will not render anything. Simply do the token refresh
 */
const AuthRefresh = (props) => {
  const { refresh } = props

  useEffect(() => {
    const refreshToken = localStorage.getItem('refresh_token')
    if (refreshToken) {
      refresh({ refresh_token: refreshToken })
    }
  }, [])

  return null
}

const mapDispatchToProps = (dispatch) => {
  return {
    refresh: (data) => dispatch(refresh(data)).then(() => dispatch(retrieveSubscription()))
  }
}

export default connect(null, mapDispatchToProps)(AuthRefresh)
