import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from '../constants/styles'

export const LargeButton = styled(Button)`
  height: 52px;
  border-radius: 10px;
`

export const LinkButton = styled(Link)`
  border-radius: 10px;
  display: inline-block;
  color: ${COLORS.ORANGE_FLEX};
  border: 1px solid ${COLORS.ORANGE_FLEX};
  text-decoration: none;
  text-align: center;
  line-height: 52px;

  ${(props) =>
    props.block &&
    `
    display: block;
    width: 100%;
  `}

  ${(props) =>
    props.type === 'primary' &&
    `
    color: ${COLORS.WHITE};
    background-color: ${COLORS.ORANGE_FLEX};
    border-color: ${COLORS.ORANGE_FLEX};
  `}
`
