import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../constants/styles'
import SimpleBox from '../../ui/simple-box'
import { LinkButton } from '../../ui/button'
import { SpanSubtle } from '../../ui/text'
import { PUBLIC_ASSET_URLS, URLS } from '../../constants'

export const StyledHeader = styled.div`
  color: ${COLORS.BLUE};
  font-size: 32px;
  font-weight: 600;
  text-align: center;
`

const AuthContainer = (props) => {
  return (
    <SimpleBox>
      <StyledHeader>3-Day Free Trials</StyledHeader>
      <SpanSubtle style={{ display: 'block', textAlign: 'center' }}>
        No credit cards required
      </SpanSubtle>
      <img
        style={{ width: '90%', margin: '0 auto', display: 'block' }}
        src={PUBLIC_ASSET_URLS.AUTH_DELIVERY}
        alt="delivery"
      />
      <LinkButton
        to={URLS.AUTH_LOGIN}
        style={{ marginTop: '50px' }}
        type="primary"
        block
      >
        Sign In
      </LinkButton>
      <LinkButton to={URLS.AUTH_SIGNUP} style={{ marginTop: '15px' }} block>
        Create An Account
      </LinkButton>
    </SimpleBox>
  )
}

export default AuthContainer
